import React from 'react';

import { ThemeProvider } from 'styled-components';
import { Router } from './routes/routes';

import { ModalProvider } from './contexts/ModalContext';
import { SidebarProvider } from './contexts/SidebarContext';
import { UsersProvider } from './contexts/UsersContext';
import { AuthProvider } from './contexts/AuthContext';
import { MembersTagsProvider } from './contexts/MembersTagsContext';

import { GlobalStyle } from './styles/global';
import { theme } from './styles/theme/theme';

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <MembersTagsProvider>
          <UsersProvider>
            <SidebarProvider>
              <ModalProvider>
                <GlobalStyle />
                <Router />
              </ModalProvider>
            </SidebarProvider>
          </UsersProvider>
        </MembersTagsProvider>
      </AuthProvider>
    </ThemeProvider>
  );
}
