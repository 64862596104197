// import { fil } from 'date-fns/locale';
import React, { createContext, ReactNode, useState } from 'react';

import { db, firestore } from '../services/firebase';

type Member = {
  id: string;
  name: string;
  department: string;
  locale: string;
  score: Score;
  totalScore: number;
};

type MostInteractiveMember = {
  id: string;
  name: string;
  score: number;
};

type Tag = {
  id: number;
  name: string;
  score: number;
};

type MembersTagsContextData = {
  members: Member[];
  allMembers: Member[];
  mostInteractiveMembers: MostInteractiveMember[];
  membersNames: string[];
  departments: string[];
  locales: string[];
  tags: Tag[];
  dateFilter: DateFilterObject;
  nameFilter: string[];
  departmentFilter: string[];
  localeFilter: string[];
  isLoading: boolean;
  isLoadingNewMembers: boolean;
  isEmpty: boolean;
  isFiltered: boolean;
  isDateFiltered: boolean;
  isTableFull: boolean;
  isHashtagFiltered: boolean;
  selectedHashtag: string;
  getMembers: () => void;
  getAllMembers: () => void;
  getMoreMembers: () => void;
  // eslint-disable-next-line no-unused-vars
  getMembersByDateFilter: (initialDate: string, finalDate: string) => void;
  // eslint-disable-next-line no-unused-vars
  getMembersByNameFilter: (names: string[]) => void;
  // eslint-disable-next-line no-unused-vars
  getMembersByDepartmentFilter: (selectedDepartments: string[]) => void;
  // eslint-disable-next-line no-unused-vars
  getMembersByLocaleFilter: (selectedLocales: string[]) => void;
  // eslint-disable-next-line no-unused-vars
  filterByHashtag: (hashtag: string) => void;
  getMembersNames: () => void;
  getDepartments: () => void;
  getLocales: () => void;
  getMostInteractiveMembers: () => void;
  getTagsInteractions: () => void;
  clearFilters: () => void;
  clearDateFilter: () => void;
  clearCSVFilters: () => void;
};

type MembersTagsProviderProps = {
  children: ReactNode;
};

type DateFilterObject = {
  initialDate: Date | string;
  finalDate: Date | string;
};

interface Score {
  artista: number;
  credencial: number;
  melhoria: number;
  original: number;
  thanks: number;
}

export const MembersTagsContext = createContext<MembersTagsContextData>(
  {} as MembersTagsContextData,
);

export function MembersTagsProvider({ children }: MembersTagsProviderProps) {
  const [members, setMembers] = useState<Member[]>([]);
  const [allMembers, setAllMembers] = useState<Member[]>([]);
  const [mostInteractiveMembers, setMostInteractiveMembers] = useState<
    MostInteractiveMember[]
  >([]);
  const [membersNames, setMembersNames] = useState<string[]>([]);
  const [departments, setDepartments] = useState<string[]>([]);
  const [locales, setLocales] = useState<string[]>([]);
  const [tags, setTags] = useState<Tag[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingNewMembers, setIsLoadingNewMembers] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);
  const [isDateFiltered, setIsDateFiltered] = useState(false);
  const [isHashtagFiltered, setIsHashtagFiltered] = useState(false);
  const [selectedHashtag, setSelectedHashtag] = useState('');
  const [lastMember, setLastMember] = useState<any>();
  const [dateFilter, setDateFilter] = useState<DateFilterObject>({
    initialDate: '',
    finalDate: '',
  });
  const [nameFilter, setNameFilter] = useState<string[]>([]);
  const [departmentFilter, setDepartmentFilter] = useState<string[]>([]);
  const [localeFilter, setLocaleFilter] = useState<string[]>([]);

  const getMembers = async () => {
    const response = await db
      .collection('members')
      .orderBy('totalScore', 'desc')
      .limit(100)
      .get();

    if (!response.empty) {
      const data = response.docs.map(doc => {
        return {
          id: doc.id,
          name: doc.data().name,
          department: doc.data().department,
          locale: doc.data().locale,
          score: doc.data().score,
          totalScore: doc.data().totalScore,
        };
      });

      const lastMemberOnTheList = response.docs[response.docs.length - 1];
      setLastMember(lastMemberOnTheList);
      setMembers(data);
    } else {
      setIsEmpty(true);
    }
    setIsLoading(false);
  };

  const getAllMembers = async () => {
    const response = await db
      .collection('members')
      .orderBy('totalScore', 'desc')
      .get();

    if (!response.empty) {
      const data = response.docs.map(doc => {
        return {
          id: doc.id,
          name: doc.data().name,
          department: doc.data().department,
          locale: doc.data().locale,
          score: doc.data().score,
          totalScore: doc.data().totalScore,
        };
      });
      setAllMembers(data);
    } else {
      setIsEmpty(true);
    }
    setIsLoading(false);
  };

  const getMoreMembers = () => {
    setIsLoadingNewMembers(true);
    db.collection('members')
      .orderBy('totalScore', 'desc')
      .startAfter(lastMember)
      .limit(100)
      .get()
      .then(response => {
        if (!response.empty) {
          const newMembers = response.docs.map(doc => {
            return {
              id: doc.id,
              name: doc.data().name,
              department: doc.data().department,
              locale: doc.data().locale,
              score: doc.data().score,
              totalScore: doc.data().totalScore,
            };
          });

          const lastMemberOnTheList = response.docs[response.docs.length - 1];
          setLastMember(lastMemberOnTheList);
          setMembers(data => [...data, ...newMembers]);
        } else {
          setIsEmpty(true);
        }
      });
    setIsLoadingNewMembers(false);
  };

  const getScoreByDateFilter = async (
    initialDate: Date | string,
    finalDate: Date | string,
    name: string,
  ) => {
    setDateFilter({
      initialDate,
      finalDate,
    });

    const startDate = firestore.Timestamp.fromDate(new Date(initialDate));
    const endDate = firestore.Timestamp.fromDate(new Date(finalDate));

    const response = await db
      .collection('posts')
      .where('collab', '==', name)
      .where('created_time', '>=', startDate)
      .where('created_time', '<=', endDate)
      .get();

    if (!response.empty) {
      const amount: Score = {
        artista: 0,
        credencial: 0,
        melhoria: 0,
        original: 0,
        thanks: 0,
      };
      response.docs.map(doc => {
        doc
          .data()
          .message_tag.map(
            (tag: { id: number; name: string; score: number }) => {
              if (tag.id === 56) {
                amount.melhoria += 1;
              } else if (tag.id === 57) {
                amount.thanks += 1;
              } else if (tag.id === 59) {
                amount.credencial += 1;
              } else if (tag.id === 60) {
                amount.artista += 1;
              } else if (tag.id === 61) {
                amount.original += 1;
              }
              return amount;
            },
          );
        return amount;
      });
      return amount;
    }
    const amount: Score = {
      artista: 0,
      credencial: 0,
      melhoria: 0,
      original: 0,
      thanks: 0,
    };
    return amount;
  };

  const getMembersByDateFilter = async (
    initialDate: Date | string,
    finalDate: Date | string,
  ) => {
    const response = await db
      .collection('members')
      .orderBy('name', 'desc')
      .limit(100)
      .get();

    if (!response.empty) {
      const data = response.docs.map(doc => {
        return {
          id: doc.id,
          name: doc.data().name,
          department: doc.data().department,
          locale: doc.data().locale,
          score: doc.data().score,
          totalScore: doc.data().totalScore,
        };
      });
      const filteredMembers = await Promise.all(
        data.map(async member => {
          const { name } = member;
          const filteredScore = await getScoreByDateFilter(
            initialDate,
            finalDate,
            name,
          );
          return {
            id: member.id,
            name,
            department: member.department,
            locale: member.locale,
            score: filteredScore,
            totalScore:
              filteredScore.artista +
              filteredScore.credencial +
              filteredScore.melhoria +
              filteredScore.original +
              filteredScore.thanks,
          };
        }),
      );
      // eslint-disable-next-line func-names

      const filteredMembersFinal = filteredMembers.filter(
        item => item.totalScore !== 0,
      );

      filteredMembersFinal.sort((a: Member, b: Member) =>
        a.totalScore > b.totalScore ? -1 : 1,
      );
      setIsFiltered(true);
      setMembers(filteredMembersFinal);
      setIsDateFiltered(true);
    } else {
      setIsEmpty(true);
    }
    setIsLoading(false);
  };

  const getMembersByNameFilter = async (names: string[]) => {
    if (names.length === 0) {
      getMembers();
      setIsFiltered(false);
      return;
    }

    setNameFilter(names);

    const response = await db
      .collection('members')
      .where('name', 'in', names)
      .orderBy('totalScore', 'desc')
      .limit(100)
      .get();

    if (!response.empty) {
      const data = response.docs.map(doc => {
        return {
          id: doc.id,
          name: doc.data().name,
          department: doc.data().department,
          locale: doc.data().locale,
          score: doc.data().score,
          totalScore: doc.data().totalScore,
        };
      });
      setMembers(data);
      setIsFiltered(true);
    } else {
      setIsEmpty(true);
    }
    setIsLoading(false);
  };

  const getMembersByDepartmentFilter = async (
    selectedDepartments: string[],
  ) => {
    if (selectedDepartments.length === 0) {
      getMembers();
      setIsFiltered(false);
      return;
    }

    setDepartmentFilter(selectedDepartments);

    const response = await db
      .collection('members')
      .where('department', 'in', selectedDepartments)
      .orderBy('totalScore', 'desc')
      .limit(100)
      .get();

    if (!response.empty) {
      const data = response.docs.map(doc => {
        return {
          id: doc.id,
          name: doc.data().name,
          department: doc.data().department,
          locale: doc.data().locale,
          score: doc.data().score,
          totalScore: doc.data().totalScore,
        };
      });
      setMembers(data);
      setIsFiltered(true);
    } else {
      setMembers([]);
      return;
    }
    setIsLoading(false);
  };

  const getMembersByLocaleFilter = async (selectedLocales: string[]) => {
    if (selectedLocales.length === 0) {
      getMembers();
      setIsFiltered(false);
      return;
    }

    setLocaleFilter(selectedLocales);

    const response = await db
      .collection('members')
      .where('locale', 'in', selectedLocales)
      .orderBy('totalScore', 'desc')
      .limit(100)
      .get();

    if (!response.empty) {
      const data = response.docs.map(doc => {
        return {
          id: doc.id,
          name: doc.data().name,
          department: doc.data().department,
          locale: doc.data().locale,
          score: doc.data().score,
          totalScore: doc.data().totalScore,
        };
      });
      setMembers(data);
      setIsFiltered(true);
    } else {
      return;
    }
    setIsLoading(false);
  };

  const filterByHashtag = async (hashtag: string | undefined) => {
    if (hashtag !== undefined) {
      getAllMembers().then(() => {
        const filteredMembers = allMembers.filter(
          item => item.score[hashtag as keyof Score] !== 0,
        );
        filteredMembers.sort((a: Member, b: Member) =>
          a.score[hashtag as keyof Score] > b.score[hashtag as keyof Score]
            ? -1
            : 1,
        );
        setIsFiltered(true);
        setMembers(filteredMembers);
        setSelectedHashtag(hashtag);
        setIsHashtagFiltered(true);
      });
    } else {
      getMembers();
      setSelectedHashtag('');
      setIsFiltered(true);
      setIsHashtagFiltered(false);
    }
  };

  const getMembersNames = async () => {
    const response = await db
      .collection('members')
      .orderBy('name', 'asc')
      .orderBy('totalScore', 'desc')
      .get();
    const data = response.docs.flatMap(doc => {
      return doc.data().name;
    });

    setMembersNames(data);
  };

  const getDepartments = async () => {
    const response = await db
      .collection('departments')
      .orderBy('name', 'asc')
      .get();
    const data = response.docs.map(doc => {
      return doc.data().name;
    });
    setDepartments(data);
  };

  const getLocales = async () => {
    const response = await db
      .collection('locales')
      .orderBy('name', 'asc')
      .get();
    const data = response.docs.map(doc => {
      return doc.data().name;
    });
    setLocales(data);
  };

  const getMostInteractiveMembers = async () => {
    const response = await db
      .collection('members')
      .orderBy('totalScore', 'desc')
      .limit(5)
      .get();

    if (!response.empty) {
      const data = response.docs.map(doc => {
        return {
          id: doc.id,
          name: doc.data().name,
          score: doc.data().totalScore,
        };
      });
      setMostInteractiveMembers(data);
    }
  };

  const getTagsInteractions = async () => {
    const response = await db
      .collection('aggregations')
      .doc('validations')
      .get();

    const data = response.data()?.tags;
    setTags(data);
  };

  const clearFilters = () => {
    getMembers();
    setIsFiltered(false);
    setNameFilter([]);
    setDepartmentFilter([]);
    setLocaleFilter([]);
  };

  const clearCSVFilters = () => {
    setIsFiltered(false);
    setIsDateFiltered(false);
    setDateFilter({
      initialDate: '',
      finalDate: '',
    });
    setNameFilter([]);
    setDepartmentFilter([]);
    setLocaleFilter([]);
  };

  const clearDateFilter = () => {
    setIsDateFiltered(false);
    setDateFilter({
      initialDate: '',
      finalDate: '',
    });
    getMembers();
  };

  const isTableFull = members.length >= 100;

  return (
    <MembersTagsContext.Provider
      value={{
        members,
        allMembers,
        mostInteractiveMembers,
        departments,
        locales,
        tags,
        membersNames,
        dateFilter,
        nameFilter,
        localeFilter,
        departmentFilter,
        isLoading,
        isEmpty,
        isFiltered,
        isTableFull,
        isDateFiltered,
        isLoadingNewMembers,
        isHashtagFiltered,
        selectedHashtag,
        getMembers,
        getAllMembers,
        getMoreMembers,
        getMembersByDateFilter,
        getMembersByNameFilter,
        getMembersByDepartmentFilter,
        getMembersByLocaleFilter,
        filterByHashtag,
        getDepartments,
        getLocales,
        getMembersNames,
        getTagsInteractions,
        getMostInteractiveMembers,
        clearFilters,
        clearDateFilter,
        clearCSVFilters,
      }}
    >
      {children}
    </MembersTagsContext.Provider>
  );
}
