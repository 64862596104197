import React, { useState } from 'react';

import Select, { OptionTypeBase } from 'react-select';
import makeAnimated from 'react-select/animated';

import { useMembersTags } from '../../../hooks/useMembersTags';
import { useSidebar } from '../../../hooks/useSidebar';

import { Container } from './styles';

const animatedComponents = makeAnimated();

type ReceivedValue = {
  value: string;
  label: string;
};

export function SelectHashtag() {
  const { filterByHashtag } = useMembersTags();
  const { theme } = useSidebar();

  const [placeholder, setPlaceholder] = useState('Filtrar por hashtag');

  const options = [
    { value: 'artista', label: '#artista' },
    { value: 'credencial', label: '#credencial' },
    { value: 'melhoria', label: '#melhoria' },
    { value: 'original', label: '#original' },
    { value: 'thanks', label: '#thanks' },
  ];

  const handleChange = (
    receivedValue: ReceivedValue | OptionTypeBase | null,
  ) => {
    const selectedHashtag = receivedValue?.value;
    filterByHashtag(selectedHashtag);
  };

  const handleFocus = () => {
    setPlaceholder('');
  };

  const handleBlur = () => {
    setPlaceholder('Filtrar por hashtag');
  };

  return (
    <Container
      onFocus={handleFocus}
      onBlur={handleBlur}
      className={theme ? 'dark' : 'light'}
    >
      {theme ? (
        <Select
          isClearable
          onChange={handleChange}
          options={options}
          components={animatedComponents}
          placeholder={placeholder}
          classNamePrefix="react-select-dark"
        />
      ) : (
        <Select
          isClearable
          onChange={handleChange}
          options={options}
          components={animatedComponents}
          placeholder={placeholder}
          classNamePrefix="react-select-light"
        />
      )}
    </Container>
  );
}
