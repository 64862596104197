import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  margin-bottom: 1rem;
  gap: 1rem;

  .select-name-dept {
    margin: 1rem;
    width: 24%;
  }

  .select-location {
    width: 24%;
  }
`;

export const Linha = styled.div`
  height: 110px;
  &.dark {
    border-right: 1px solid #fafbfc;
  }
  &.light {
    border-right: 1px solid #2c3135;
  }
`;
