/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { ToggleTheme, CircleToggle } from './styles';
import { useSidebar } from '../../hooks/useSidebar';

const Toggle = () => {
  const { theme, setTheme } = useSidebar();
  const toggleTheme = theme ? 'toggleDark' : 'toggleLight';
  const circleToggleTheme = theme ? 'circleToggleDark' : 'circleToggleLight';
  const toggleOnOff = theme ? 'circleToggleOn' : 'circleToggleOff';

  function handleThemeChange() {
    setTheme(!theme);
    localStorage.setItem('theme', JSON.stringify(!theme));
  }

  return (
    <>
      <ToggleTheme
        className={`toggle ${toggleTheme}`}
        onClick={() => handleThemeChange()}
      >
        <CircleToggle
          className={` circleToggle ${toggleOnOff} ${circleToggleTheme}`}
        />
      </ToggleTheme>
    </>
  );
};

export default Toggle;
