import styled from 'styled-components';

export const UserMenuStyle = styled.div.attrs(props => ({
  className: props.className,
}))`
  &.userMenu {
    position: absolute;
    bottom: 15px;
    width: 250px;
    height: 100px;
    border-radius: 10px;
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    transition: all ease 0.2s;
  }
  &.userMenuOpen {
    left: 305px;
    transition: all ease 0.2s;
  }
  &.userMenuClose {
    left: 65px;
    transition: all ease 0.2s;
  }
  &.userMenuDark {
    border: 1px solid #3f484f;
    background-color: #2c3135;
    color: #fafbfc;
  }
  &.userMenuLight {
    border: 1px solid #cccccc;
    background-color: #f6f7f8;
    color: #2c3135;
  }
`;

export const UserOptionTheme = styled.div.attrs(props => ({
  className: props.className,
}))`
  &.userOptionDark {
    display: flex;
    width: 240px;
    height: 40px;
    border-radius: 10px;
    margin: 7px 5px;
    align-items: center;
  }
  &.userOptionLight {
    display: flex;
    width: 240px;
    height: 40px;
    border-radius: 10px;
    margin: 7px 5px;
    align-items: center;
  }
  &.userOptionDark:hover {
    background-color: #45525b;
  }
  &.userOptionLight:hover {
    background-color: #dddedf;
  }
  &.userOptionDark span,
  &.userOptionLight span {
    margin: 13px 12.5px 12.5px 0;
  }
`;

export const IconUser = styled.div.attrs(props => ({
  className: props.className,
}))`
  .iconUser {
    width: 20px;
    height: 20px;
    margin: 10px;
  }
`;
export const ToggleTheme = styled.div.attrs(props => ({
  className: props.className,
}))`
  .toggleTheme {
    margin-left: auto;
    margin-right: 10px;
  }
`;
export const Success = styled.p`
  color: ${props => props.theme.colors.green};
  font-family: ${props => props.theme.fonts.mainFont};
  margin: 1rem;
  font-weight: bold;
  font-size: 1rem;
  align-text: center;
`;
