import React from 'react';

import { useMembersTags } from '../../hooks/useMembersTags';
import { useSidebar } from '../../hooks/useSidebar';

import { StyledTable } from './styles';

interface Score {
  artista: number;
  credencial: number;
  melhoria: number;
  original: number;
  thanks: number;
}

export function HashtagsTable() {
  const { members, isHashtagFiltered, selectedHashtag } = useMembersTags();
  const { theme } = useSidebar();

  const hashtagTitle = `#${selectedHashtag
    .charAt(0)
    .toUpperCase()}${selectedHashtag.slice(1)}`;

  return (
    <StyledTable>
      {isHashtagFiltered ? (
        <table>
          <thead className={theme ? 'dark' : 'light'}>
            <tr>
              <th className={theme ? 'dark' : 'light'}>Nome</th>
              <th className={theme ? 'dark' : 'light'}>Departamento</th>
              <th className={theme ? 'dark' : 'light'}>Localização</th>
              <th className={theme ? 'dark' : 'light'}>{hashtagTitle}</th>
            </tr>
          </thead>
          <tbody className={theme ? 'dark' : 'light'}>
            {members.length !== 0 ? (
              <>
                {members.map(member => (
                  <tr key={member.id}>
                    <td className={theme ? 'dark' : 'light'}>{member.name}</td>
                    <td className={theme ? 'dark' : 'light'}>
                      {member.department}
                    </td>
                    <td className={theme ? 'dark' : 'light'}>
                      {member.locale}
                    </td>
                    <td className={theme ? 'dark' : 'light'}>
                      {member.score[selectedHashtag as keyof Score]}
                    </td>
                  </tr>
                ))}
              </>
            ) : (
              <tr>
                <td> </td>
                <td colSpan={9}>
                  <h3 className={theme ? 'dark' : 'light'}>
                    Ainda nada por aqui{' '}
                  </h3>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      ) : (
        <table>
          <thead className={theme ? 'dark' : 'light'}>
            <tr>
              <th className={theme ? 'dark' : 'light'}>Nome</th>
              <th className={theme ? 'dark' : 'light'}>Departamento</th>
              <th className={theme ? 'dark' : 'light'}>Localização</th>
              <th className={theme ? 'dark' : 'light'}>#Artista</th>
              <th className={theme ? 'dark' : 'light'}>#Credencial</th>
              <th className={theme ? 'dark' : 'light'}>#Melhoria</th>
              <th className={theme ? 'dark' : 'light'}>#Original</th>
              <th className={theme ? 'dark' : 'light'}>#Thanks</th>
              <th className={theme ? 'dark' : 'light'}>Total</th>
            </tr>
          </thead>
          <tbody className={theme ? 'dark' : 'light'}>
            {members.length !== 0 ? (
              <>
                {members.map(member => (
                  <tr key={member.id}>
                    <td className={theme ? 'dark' : 'light'}>{member.name}</td>
                    <td className={theme ? 'dark' : 'light'}>
                      {member.department}
                    </td>
                    <td className={theme ? 'dark' : 'light'}>
                      {member.locale}
                    </td>
                    <td className={theme ? 'dark' : 'light'}>
                      {member.score.artista}
                    </td>
                    <td className={theme ? 'dark' : 'light'}>
                      {member.score.credencial}
                    </td>
                    <td className={theme ? 'dark' : 'light'}>
                      {member.score.melhoria}
                    </td>
                    <td className={theme ? 'dark' : 'light'}>
                      {member.score.original}
                    </td>
                    <td className={theme ? 'dark' : 'light'}>
                      {member.score.thanks}
                    </td>
                    <td className={theme ? 'dark' : 'light'}>
                      {member.totalScore}
                    </td>
                  </tr>
                ))}
              </>
            ) : (
              <tr>
                <td> </td>
                <td colSpan={9}>
                  <h3 className={theme ? 'dark' : 'light'}>
                    Ainda nada por aqui{' '}
                  </h3>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      )}
    </StyledTable>
  );
}
