import styled from 'styled-components';

export const StyledInput = styled.input`
  width: 26rem;
  height: 2rem;
  padding: 0 1rem;
  border-radius: 10px;
  margin-bottom: 2rem;
  font-family: ${props => props.theme.fonts.textFont};
  font-size: 1.2rem;
  background-color: #f0f2f5;
  border: 1px solid #cccccc;
  color: #7d838c;

  &::placeholder {
    font-family: ${props => props.theme.fonts.textFont};
    font-size: 1.1rem;
  }

  &:focus {
    border: 1px solid ${props => props.theme.colors.orange};
  }
`;
