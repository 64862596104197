import styled from 'styled-components';

export const Menu = styled.div.attrs(props => ({ className: props.className }))`
  &.mainMenu {
    position: fixed;
    height: calc(100vh - 1px);
    display: flex;
    flex-direction: column;
    transition: all ease 0.2s;
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    z-index: 3;
  }
  &.mainMenuOpen {
    width: 300px;
  }
  &.mainMenuClose {
    width: 60px;
  }
  &.mainMenuDark {
    background-color: #2c3135;
    border: 1px solid #3f484f;
  }
  &.mainMenuLight {
    background-color: #f6f7f8;
    border: 1px solid #dddedf;
  }
`;

export const ToggleMenu = styled.div.attrs(props => ({
  className: props.className,
}))`
  &.toggleMenu {
    position: absolute;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all ease 0.2s;
    z-index: 3;
  }
  &.toggleMenuOpen {
    top: 15px;
    left: 300px;
  }
  &.toggleMenuClose {
    top: 15px;
    left: 53.5px;
  }
  &.toggleMenuDark {
    background-color: #fafbfc;
    border: 1px solid #3f484f;
  }
  &.toggleMenuLight {
    background-color: #2c3135;
    border: 1px solid #cccccc;
  }
`;

export const ArrowMenu = styled.div.attrs(props => ({
  className: props.className,
}))`
  .arrowMenuOpen {
    width: 9px;
    height: 18px;
    margin: 2px 0 0 -3px;
    transform: rotate(180deg);
  }
  .arrowMenuClose {
    width: 9px;
    height: 18px;
    margin-top: 1px;
    margin-left: 3px;
  }
`;

export const OptionMenu = styled.div.attrs(props => ({
  className: props.className,
}))`
  .link {
    text-decoration: none;
  }
  &.optionMenu {
    padding-top: 25px;
  }
  .option {
    height: 45px;
    margin: 7.5px;
    cursor: pointer;
  }
  .optionDark {
    color: #fafbfc;
  }
  .optionLight {
    color: #2c3135;
  }
  .optionOpen {
    width: 275px;
    display: flex;
    align-items: center;
  }
  .optionClose {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45px;
  }
  .option span {
    margin-left: 15px;
  }

  .optionDark:hover {
    background-color: #45525b;
    border-radius: 15px;
    cursor: pointer;
  }
  .optionLight:hover {
    background-color: #dddedf;
    border-radius: 15px;
    cursor: pointer;
  }
  .optionHighlight {
    background-color: #a7bffa !important;
    color: #0073ea !important;
    border-radius: 10px;
    transition: all ease 0.2s;
  }
  .iconMenu {
    width: 40px;
    height: 40px;
    transition: all ease 0.2s;
  }
  .iconMenuOpen {
    margin-left: 20px;
  }
`;

export const UserInfo = styled.div.attrs(props => ({
  className: props.className,
}))`
  &.userInfo {
    transition: all ease 0.2s;
    cursor: pointer;
  }
  &.userInfo span {
    margin-left: 15px;
    text-transform: capitalize;
  }
  &.userInfoDark {
    color: #fafbfc;
  }
  &.userInfoLight {
    color: #2c3135;
  }
  &.userInfoClose {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: auto;
    margin-bottom: 25px;
  }
  &.userInfoOpen {
    margin-left: 10px;
    margin-bottom: 25px;
    margin-top: auto;
    display: flex;
    align-items: center;
    align-content: flex-end;
  }
  .userphoto {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 100%;
    cursor: pointer;
  }
`;
