import styled from 'styled-components';

export const Container = styled.div`
  height: 100vh;
  text-align: center;
  &.dark {
    background-color: #2a2d30;
  }
  &.light {
    background-color: #fafbfc;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  margin-top: 20px;
`;

export const LoadMoreButton = styled.button`
  margin: 0 auto;
  padding: 1rem 1.75rem;
  border-radius: 5px;
  border: none;
  background-color: #0073ea;
  color: #fafbfc;
  font-weight: bold;
  font-size: 1rem;
  transition: filter 0.2s;
  :hover {
    background-color: #00468e;
  }
`;
