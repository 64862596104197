import styled from 'styled-components';

export const List = styled.ul`
  margin-top: 2rem;
  list-style: none;
  color: ${props => props.theme.colors.grayText};
  font-size: 1.5rem;
  font-family: ${props => props.theme.fonts.textFont};
  &.dark {
    color: #ffffff;
  }
  &.light {
    color: #2c3135;
  }
`;

export const ListItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  & + li {
    margin-top: 1.5rem;
  }
  border-bottom: 1px solid ${props => props.theme.colors.lightGray};
`;

export const UserName = styled.p`
  font-weight: bold;
  width: 14rem;
`;

export const UserScore = styled.p`
  width: 5rem;
  text-align: right;
`;
