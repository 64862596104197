import React, { useState, ReactNode, useEffect } from 'react';
import firebase from 'firebase';
import { auth, db } from '../services/firebase';

type AuthProviderProps = {
  children: ReactNode;
};

type User = firebase.User;

type AuthProviderContextType = {
  currentUser: User | null;
  isUserAdmin: boolean;
  userName: string;
  // eslint-disable-next-line no-unused-vars
  login: (
    // eslint-disable-next-line no-unused-vars
    email: string,
    // eslint-disable-next-line no-unused-vars
    password: string,
  ) => Promise<void>;
  logout: () => Promise<void>;
  // eslint-disable-next-line no-unused-vars
  resetPassword: (email: string) => Promise<void>;
  getCurrentUser: () => void;
};

export const AuthContext = React.createContext<AuthProviderContextType>(
  {} as AuthProviderContextType,
);

export function AuthProvider({ children }: AuthProviderProps) {
  const [currentUser, setCurrentUser] = useState<User | null>({} as User);
  const [loading, setLoading] = useState(true);
  const [isUserAdmin, setIsUserAdmin] = useState(false);
  const [userName, setUserName] = useState('');

  const login = async (email: string, password: string) => {
    auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL).then(() => {});
    const response = await auth.signInWithEmailAndPassword(email, password);
    setCurrentUser(response.user);
  };

  const logout = () => {
    return auth.signOut();
  };

  const resetPassword = (email: string) => {
    return auth.sendPasswordResetEmail(email);
  };

  const getCurrentUser = async () => {
    const loggedUser = auth.currentUser;
    const id = loggedUser?.uid;
    const response = await db.collection('users').doc(id).get();
    setUserName(response.data()?.name);
    setIsUserAdmin(response.data()?.isAdmin);
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setCurrentUser(user);
      setLoading(false);
    });
    return () => unsubscribe();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        isUserAdmin,
        userName,
        login,
        logout,
        resetPassword,
        getCurrentUser,
      }}
    >
      {!loading && children}
    </AuthContext.Provider>
  );
}
