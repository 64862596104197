import React, { useState } from 'react';

import Modal from 'react-modal';

import { CircularProgress } from '@material-ui/core';

import { useModals } from '../../hooks/useModals';
import { useUsers } from '../../hooks/useUsers';

import { Form, Success } from './styles';

import closeImg from '../../assets/close.svg';
import { pictureStorage } from '../../services/firebase';

Modal.setAppElement('#root');

export function DeleteModal() {
  const [isDeletingUser, setIsDeletingUser] = useState(false);

  const {
    isDeleteModalOpen,
    handleCloseDeleteModal,
    userName,
    userId,
    userEmail,
  } = useModals();

  const { deleteUser } = useUsers();
  const [deleteSuccess, setDeleteSuccess] = useState('');

  const handleDeleteUser = async () => {
    setIsDeletingUser(true);
    await pictureStorage.ref(`${userEmail}-userPicture.png`).delete();
    await deleteUser(userId);
    setIsDeletingUser(false);
    setDeleteSuccess('Usuário excluído com sucesso.');
    setTimeout(() => {
      setDeleteSuccess('');
      handleCloseDeleteModal();
    }, 3000);
  };

  return (
    <Modal
      isOpen={isDeleteModalOpen}
      onRequestClose={handleCloseDeleteModal}
      overlayClassName="modal-overlay"
      className="modal-content"
    >
      <button
        type="button"
        onClick={handleCloseDeleteModal}
        className="close-modal-btn"
      >
        <img src={closeImg} alt="Fechar modal" />
      </button>
      <Form>
        <h2>Deseja realmente excluir esse usuário?</h2>
        <p>{userName}</p>
        <button onClick={handleDeleteUser} type="button">
          {isDeletingUser ? (
            <p>
              <CircularProgress color="inherit" />{' '}
            </p>
          ) : (
            'Excluir'
          )}
        </button>
      </Form>
      {deleteSuccess && <Success>{deleteSuccess}</Success>}
    </Modal>
  );
}
