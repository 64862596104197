import React from 'react';
import { useLocation } from 'react-router-dom';
import { useSidebar } from '../../hooks/useSidebar';
import { ProductTitle, PageTitle, TitleDiv } from './styles';

const Title = () => {
  const { theme } = useSidebar();

  const mainMenuTheme = theme ? 'TitleDarkMode' : 'TitleLightMode';

  const location = useLocation();
  let title = '';
  const path = location.pathname;

  switch (path) {
    case '/home':
      title = 'Página inicial';
      break;
    case '/users':
      title = 'Usuários';
      break;
    case '/hashtags':
      title = 'Painel de Tags';
      break;
    default:
      return <div>cabo</div>;
  }

  return (
    <TitleDiv className={`Title ${mainMenuTheme}`}>
      <ProductTitle>Reconhecimento |</ProductTitle>
      <PageTitle>{title}</PageTitle>
    </TitleDiv>
  );
};

export default Title;
