import React from 'react';

import { DateAccordion } from './DateAccordion';
import { SelectDepartament } from './SelectDepartament';
import { SelectLocation } from './SelectLocation';
import { SelectName } from './SelectName';
import { DownloadButton } from './DownloadButton';
import { SelectHashtag } from './SelectHashtag';

import { useSidebar } from '../../hooks/useSidebar';

import { Container, Linha } from './styles';

export function FiltersBar() {
  const { theme } = useSidebar();
  return (
    <Container>
      <DateAccordion />
      <Linha className={theme ? 'dark' : 'light'} />
      <div className="select-name-dept">
        <SelectName />
        <SelectDepartament />
      </div>
      <div className="select-location">
        <SelectLocation />
        <SelectHashtag />
      </div>
      <DownloadButton />
    </Container>
  );
}
