import React, { useState } from 'react';

import { Link } from 'react-router-dom';

import { useAuth } from '../../hooks/useAuth';

import {
  Main,
  Button,
  BackLogin,
  Message,
  Container,
  Title,
  Subtitle,
  Content,
} from './styles';

import { Input } from '../../components/Input';

import logoReconhecimento from '../../assets/Logo-Azul-Reconhecimento.svg';
import LogoAlest from '../../assets/LogoAlest.svg';

export function ForgottenPassword() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState({
    text: '',
    color: '#EA4335',
  });

  const { resetPassword } = useAuth();

  const handleSendEmail = async (event: React.MouseEvent) => {
    event.preventDefault();
    try {
      await resetPassword(email);
      setMessage({
        text: 'Email enviado com sucesso!',
        color: 'green',
      });
      setEmail('');
    } catch (error: any) {
      switch (error.code) {
        case 'auth/invalid-email':
        case 'auth/user-disabled':
        case 'auth/user-not-found':
          setMessage({
            text: 'Email inválido',
            color: '#EA4335',
          });
          break;

        default:
          break;
      }
    }
  };

  return (
    <Main>
      <Container className="ContainerLogoAlest">
        <img src={LogoAlest} alt="Logo da Alest" className="LogoAlest" />
      </Container>
      <Title>
        <Container className="ContainerLogoRec">
          <img src={logoReconhecimento} alt="Logo Reconhecimento" />
        </Container>
        Reconhecimento
      </Title>

      <Container className="Container">
        <Subtitle>Recuperar minha senha</Subtitle>
        <Content>
          Vamos enviar um email com mais informações
          <br /> sobre como redefinir sua senha.{' '}
        </Content>
        <Input
          type="email"
          placeholder="Digite seu email"
          value={email}
          onChange={event => setEmail(event.target.value)}
        />
        {message && (
          <Message style={{ color: message.color }}>{message.text}</Message>
        )}
        <Button onClick={handleSendEmail}>Enviar email</Button>
        <Link to="/">
          <BackLogin>Voltar para página de Login</BackLogin>
        </Link>
      </Container>
    </Main>
  );
}
