import styled from 'styled-components';

export const ProductTitle = styled.span`
  font-size: 30px;
  font-weight: bold;
`;

export const PageTitle = styled.span`
  font-size: 25px;
  margin: 5px 0 0 5px;
`;

export const TitleDiv = styled.div.attrs(props => ({
  className: props.className,
}))`
  &.Title {
    width: 100vw;
    height: 90px;
    display: flex;
    align-items: center;
    padding-left: 25px;
    font-family: 'Roboto', sans-serif;
  }
  &.TitleDarkMode {
    background-color: ${props => props.theme.colors.backgroundColorDarkMode};
    color: #fafbfc;
    border-bottom: 2px solid #3f484f;
    transition: all ease 0.2s;
  }
  &.TitleLightMode {
    background-color: ${props => props.theme.colors.backgroundColorLightMode};
    color: #2c3135;
    border-bottom: 1px solid #cccccc;
    transition: all ease 0.2s;
  }
`;
