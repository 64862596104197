import React, { createContext, ReactNode, useState } from 'react';
import { db } from '../services/firebase';

import { usersApi } from '../services/usersApi';

import { capitalize } from '../utils/formatStrings';

type UsersProviderProps = {
  children: ReactNode;
};

type User = {
  id: string;
  name: string;
  email: string;
  isAdmin: boolean;
};

type UsersContextType = {
  user: User;
  allUsers: User[];
  usersNames: string[];
  getUsers: () => void;
  getMoreUsers: () => void;
  // eslint-disable-next-line no-unused-vars
  getUserCredentialsData: (id: string) => void;
  createUser: (
    // eslint-disable-next-line no-unused-vars
    name: string,
    // eslint-disable-next-line no-unused-vars
    email: string,
    // eslint-disable-next-line no-unused-vars
    password: string,
    // eslint-disable-next-line no-unused-vars
    isChecked: boolean,
  ) => void;
  // eslint-disable-next-line no-unused-vars
  deleteUser: (id: string) => void;
  getUsersNames: () => void;
  // eslint-disable-next-line no-unused-vars
  editUser: (id: string, name: string, email: string, isAdmin: boolean) => void;
  isEmpty: boolean;
  isLoading: boolean;
  isLoadingNewUsers: boolean;
};

export const UsersContext = createContext<UsersContextType>(
  {} as UsersContextType,
);

export function UsersProvider({ children }: UsersProviderProps) {
  const [user, setUser] = useState<User>({} as User);
  const [lastUser, setLastUser] = useState<any>();
  const [usersNames, setUsersNames] = useState<string[]>([]);
  const [allUsers, setAllUSers] = useState<User[]>([]);
  const [isEmpty, setIsEmpty] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingNewUsers, setIsLoadingNewUsers] = useState(false);

  const getUsers = async () => {
    const response = await db
      .collection('users')
      .orderBy('name', 'asc')
      .limit(50)
      .get();

    if (!response.empty) {
      const data = response.docs.map(doc => {
        return {
          id: doc.data().id,
          name: capitalize(doc.data().name),
          email: doc.data().email,
          isAdmin: doc.data().isAdmin,
        };
      });
      const lastUserOnTheList = response.docs[response.docs.length - 1];
      setLastUser(lastUserOnTheList);
      setAllUSers(data);
    } else {
      setIsEmpty(true);
    }
    setIsLoading(false);
  };

  const getMoreUsers = () => {
    setIsLoadingNewUsers(true);
    db.collection('users')
      .orderBy('name', 'asc')
      .startAfter(lastUser)
      .limit(50)
      .get()
      .then(response => {
        if (!response.empty) {
          const newUsers = response.docs.map(doc => {
            return {
              id: doc.data().id,
              name: capitalize(doc.data().name),
              email: doc.data().email,
              isAdmin: doc.data().isAdmin,
            };
          });

          const lastUserOnTheList = response.docs[response.docs.length - 1];
          setLastUser(lastUserOnTheList);
          setAllUSers(data => [...data, ...newUsers]);
        } else {
          setIsEmpty(true);
        }
      });
    setIsLoadingNewUsers(false);
  };

  const getUserCredentialsData = async (id: string = '') => {
    const response = await db.collection('users').doc(id).get();
    const userData = {
      id,
      name: response.data()?.name,
      email: response.data()?.email,
      isAdmin: response.data()?.isAdmin,
    };
    setUser(userData);
  };

  const getUsersNames = async () => {
    const response = await db.collection('users').orderBy('name', 'asc').get();
    const data = response.docs.map(doc => {
      return doc.data().name;
    });

    setUsersNames(data);
  };

  const createUser = async (
    name: string,
    email: string,
    password: string,
    isChecked: boolean,
  ) => {
    await usersApi.post('/create', {
      name,
      email,
      password,
      isAdmin: isChecked,
    });
    getUsers();
  };

  const deleteUser = async (id: string) => {
    await usersApi.delete('/delete', {
      data: {
        id,
      },
    });
    getUsers();
    getUsersNames();
  };

  const editUser = async (
    id: string,
    name: string,
    email: string,
    isAdmin: boolean,
  ) => {
    try {
      await usersApi.put('/edit', {
        id,
        name,
        email,
        isAdmin,
      });
      getUsers();
    } catch {
      throw new Error();
    }
  };

  return (
    <UsersContext.Provider
      value={{
        user,
        allUsers,
        usersNames,
        getUsers,
        getMoreUsers,
        getUserCredentialsData,
        createUser,
        getUsersNames,
        deleteUser,
        editUser,
        isEmpty,
        isLoading,
        isLoadingNewUsers,
      }}
    >
      {children}
    </UsersContext.Provider>
  );
}
