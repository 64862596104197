import React from 'react';
import { Pie } from 'react-chartjs-2';
import { useSidebar } from '../../../hooks/useSidebar';

import { useMembersTags } from '../../../hooks/useMembersTags';

import { Container } from './styles';

export function TagsChart() {
  const { tags } = useMembersTags();
  const { theme } = useSidebar();

  const tagslabels = tags.map(tag => tag.name);
  const score = tags.map(tag => tag.score);

  const data = {
    legend: {
      display: false,
    },
    labels: tagslabels,
    datasets: [
      {
        label: 'Número de hashtags',
        data: score,
        borderWidth: 1,
        backgroundColor: [
          `rgba(16, 96, 170, 0.9)`,
          `rgba(95, 178, 255, 0.9)`,
          `rgba(174, 216, 255, 0.9)`,
          `rgba(38, 151, 255, 0.9)`,
          `rgba(31, 114, 191, 0.9)`,
        ],
        borderColor: [
          `rgb(16, 96, 170)`,
          `rgb(95, 178, 255)`,
          `rgb(174, 216, 255)`,
          `rgb(38, 151, 255)`,
          `rgb(31, 114, 191)`,
        ],
      },
    ],
  };

  const options: any = {
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          color: theme ? `#ffffff` : `#2C3135`,
        },
      },
    },
  };

  return (
    <Container>
      <Pie data={data} options={options} />
    </Container>
  );
}
