import React, { useEffect } from 'react';

import { CircularProgress } from '@material-ui/core';

import { useModals } from '../../hooks/useModals';
import { useUsers } from '../../hooks/useUsers';
import { useSidebar } from '../../hooks/useSidebar';

import { Button } from '../Button';

import { StyledTable, LoadContainer } from './styles';

export function UsersTable() {
  const { handleOpenEditModal, handleOpenDeleteModal } = useModals();
  const { allUsers, getUsers, isLoading } = useUsers();
  const { theme } = useSidebar();

  useEffect(() => {
    getUsers();
  }, []);

  if (isLoading) {
    return (
      <LoadContainer>
        <CircularProgress color="inherit" />;
      </LoadContainer>
    );
  }

  return (
    <StyledTable>
      <table>
        <thead className={theme ? 'dark' : 'light'}>
          <tr>
            <th>Nome</th>
            <th>Email</th>
            <th>Opções</th>
          </tr>
        </thead>
        <tbody>
          {allUsers.map(user => (
            <tr key={user.id}>
              <td className={theme ? 'dark' : 'light'}>{user.name}</td>
              <td className={theme ? 'dark' : 'light'}>{user.email}</td>
              <td className={theme ? 'dark' : 'light'}>
                <Button
                  onClick={() =>
                    handleOpenEditModal(user.id, user.name, user.email)
                  }
                  mode="edit"
                >
                  Editar
                </Button>
                <Button
                  onClick={() =>
                    handleOpenDeleteModal(user.id, user.name, user.email)
                  }
                  mode="delete"
                >
                  Excluir
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </StyledTable>
  );
}
