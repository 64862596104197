import React, { useState } from 'react';

import DateFnsUtils from '@date-io/date-fns';
import { ptBR } from 'date-fns/locale';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import { useMembersTags } from '../../../hooks/useMembersTags';
import { useSidebar } from '../../../hooks/useSidebar';

import {
  Container,
  DataSelectorContainer,
  ButtonsContainer,
  DateFilterButton,
  ClearFilterButton,
} from './styles';

export function DateAccordion() {
  const { getMembersByDateFilter, isDateFiltered, clearDateFilter } =
    useMembersTags();
  const { theme } = useSidebar();

  const [initialDate, setInitialDate] = useState('2021-09-01T01:11:54');
  const [finalDate, setFinalDate] = useState('2021-12-31T21:11:54');

  const handleFilter = () => {
    getMembersByDateFilter(initialDate, finalDate);
  };

  const handleInitialDateChange = (date: any) => {
    const formattedInitialDate = date?.toISOString();
    setInitialDate(formattedInitialDate);
  };

  const handleFinalDateChange = (date: any) => {
    const formattedFinalDate = date?.toISOString();
    setFinalDate(formattedFinalDate);
  };

  return (
    <Container className={theme ? 'dark' : 'light'}>
      <DataSelectorContainer>
        <span className={`initial ${theme ? 'labelDark ' : 'labelLight '}`}>
          Inicial
        </span>
        <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
          <KeyboardDatePicker
            autoOk
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            id="date-picker-inline"
            value={initialDate}
            onChange={handleInitialDateChange}
            size="small"
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            InputProps={{
              disableUnderline: true,
            }}
            className={`dateInput ${
              theme ? 'labelDark dateInputDark' : 'labelLight dateInputLight'
            }`}
          />
          <span className={`final ${theme ? 'labelDark ' : 'labelLight '}`}>
            Final
          </span>
          <KeyboardDatePicker
            autoOk
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            id="date-picker-inline2"
            value={finalDate}
            onChange={handleFinalDateChange}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            InputProps={{
              disableUnderline: true,
            }}
            className={`dateInput ${
              theme ? 'labelDark dateInputDark' : 'labelLight dateInputLight'
            }`}
          />
        </MuiPickersUtilsProvider>
      </DataSelectorContainer>
      <ButtonsContainer>
        <DateFilterButton onClick={handleFilter}>Filtrar</DateFilterButton>
        {isDateFiltered && (
          <ClearFilterButton onClick={clearDateFilter} type="button">
            Limpar
          </ClearFilterButton>
        )}
      </ButtonsContainer>
    </Container>
  );
}
