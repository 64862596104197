import React from 'react';
import Title from '../Title';
import { Container } from './styles';
import { useSidebar } from '../../hooks/useSidebar';

export const Header = () => {
  const { theme, toggleMenu } = useSidebar();

  const openMenu = toggleMenu ? 'ContainerMenuOpen' : 'ContainerMenuClose';
  const mainMenuTheme = theme ? 'ContainerDarkMode' : 'ContainerLightMode';

  return (
    <Container className={`Container ${openMenu} ${mainMenuTheme}`}>
      <Title />
    </Container>
  );
};
