import React from 'react';

import { BrowserRouter, Switch, Route } from 'react-router-dom';

import { Login } from '../pages/Login';
import { Home } from '../pages/Home';
import { Users } from '../pages/Users';
import { ForgottenPassword } from '../pages/ForgottenPassword';
import { Hashtags } from '../pages/Hashtags';
import { Sidebar } from '../components/Sidebar';

import { PrivateRoute } from './PrivateRoutes';

export function Router() {
  return (
    <BrowserRouter>
      <Route
        exact
        path={['/home', '/users', '/hashtags']}
        component={Sidebar}
      />
      <Switch>
        <Route exact path="/" component={Login} />
        <Route exact path="/reset-password" component={ForgottenPassword} />
        <PrivateRoute exact path="/home" component={Home} />
        <PrivateRoute exact path="/users" component={Users} />
        <PrivateRoute exact path="/hashtags" component={Hashtags} />
      </Switch>
    </BrowserRouter>
  );
}
