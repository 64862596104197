import styled from 'styled-components';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-family: ${props => props.theme.fonts.mainFont};
  gap: 1rem;
  margin-bottom: 2rem;

  h2 {
    color: ${props => props.theme.colors.darkBlue};
    width: 20rem;
    font-size: 2rem;
    text-align: center;
    margin-bottom: 3rem;
  }

  p {
    color: ${props => props.theme.colors.darkBlue};
    font-weight: bold;
    font-size: 1.4rem;
    margin-bottom: 2rem;
  }

  button {
    width: 16rem;
    padding: 0.5rem 0;
    background: ${props => props.theme.colors.red};
    height: 3rem;
    font-size: 1.3rem;
    border: none;
    border-radius: 5px;
    margin-top: 1.5rem;
    color: ${props => props.theme.colors.grayText};
    font-weight: bold;

    &:hover {
      filter: brightness(0.9);
    }
  }
`;

export const Success = styled.p`
  color: ${props => props.theme.colors.green};
  font-family: ${props => props.theme.fonts.mainFont};
  margin: 1rem;
  font-weight: bold;
  font-size: 1.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
