import styled from 'styled-components';

export const ToggleTheme = styled.div.attrs(props => ({
  className: props.className,
}))`
  &.toggle {
    position: relative;
    width: 50px;
    height: 20px;
    border-radius: 10px;
  }
  &.toggleDark {
    background-color: #0073ea;
    border: 1px solid #0073ea;
    transition: all ease 0.2s;
  }
  &.toggleLight {
    background-color: #818386;
    border: 1px solid #cccccc;
    transition: all ease 0.2s;
  }
`;

export const CircleToggle = styled.div.attrs(props => ({
  className: props.className,
}))`
  &.circleToggle {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    margin: 1px;
    transition: all ease 0.2s;
  }
  &.circleToggleOn {
    position: flex;
    margin-left: 32px;
  }
  &.circleToggleOff {
    position: flex;
    left: 0;
  }
  &.circleToggleDark {
    background-color: #818386;
    transition: all ease 0.2s;
  }
  &.circleToggleLight {
    background-color: #fafbfc;
    transition: all ease 0.2s;
  }
`;
