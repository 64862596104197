import React, { useState, MouseEvent } from 'react';

import { FaFileDownload } from 'react-icons/fa';

import { Popover } from '@material-ui/core';

import { useMembersTags } from '../../../hooks/useMembersTags';

import { csvAPI } from '../../../services/csvAPI';

import {
  Button,
  PopoverTitle,
  ButtonsContainer,
  PopoverConfirmButton,
  PopoverDenyButton,
} from './styles';

export function DownloadButton() {
  const [isLoading, setIsLoading] = useState(false);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const {
    dateFilter,
    nameFilter,
    departmentFilter,
    localeFilter,
    isFiltered,
    clearCSVFilters,
  } = useMembersTags();

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const downloadCSV = async (limit: boolean) => {
    setIsLoading(true);
    handleClose();
    if (isFiltered) {
      if (dateFilter.initialDate !== '') {
        try {
          const response = await csvAPI.post('/getDownload', {
            hasFilter: false,
            hasLimit: limit,
            name: [],
            department: [],
            locale: [],
            initialDate: dateFilter.initialDate,
            finalDate: dateFilter.finalDate,
          });
          const { url } = response.data;
          const link = document.createElement('a');
          link.setAttribute('href', url);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          setIsLoading(false);
          clearCSVFilters();
        } catch (err) {
          setIsLoading(false);
        }
      } else if (nameFilter.length !== 0) {
        try {
          const response = await csvAPI.post('/getDownload', {
            hasFilter: true,
            hasLimit: limit,
            name: nameFilter,
            department: [],
            locale: [],
            initialDate: dateFilter.initialDate,
            finalDate: dateFilter.finalDate,
          });
          const { url } = response.data;
          const link = document.createElement('a');
          link.setAttribute('href', url);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          setIsLoading(false);
          clearCSVFilters();
        } catch (err) {
          setIsLoading(false);
        }
      } else if (departmentFilter.length !== 0) {
        try {
          const response = await csvAPI.post('/getDownload', {
            hasFilter: true,
            hasLimit: limit,
            name: [],
            department: departmentFilter,
            locale: [],
            initialDate: dateFilter.initialDate,
            finalDate: dateFilter.finalDate,
          });
          const { url } = response.data;
          const link = document.createElement('a');
          link.setAttribute('href', url);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          setIsLoading(false);
          clearCSVFilters();
        } catch (err) {
          setIsLoading(false);
        }
      } else if (localeFilter.length !== 0) {
        try {
          const response = await csvAPI.post('/getDownload', {
            hasFilter: true,
            hasLimit: limit,
            name: [],
            department: [],
            locale: localeFilter,
            initialDate: dateFilter.initialDate,
            finalDate: dateFilter.finalDate,
          });
          const { url } = response.data;
          const link = document.createElement('a');
          link.setAttribute('href', url);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          setIsLoading(false);
          clearCSVFilters();
        } catch (err) {
          setIsLoading(false);
        }
      }
    } else {
      try {
        const response = await csvAPI.post('/getDownload', {
          hasFilter: false,
          hasLimit: limit,
          name: [],
          department: [],
          locale: [],
          initialDate: '',
          finalDate: '',
        });
        const { url } = response.data;
        const link = document.createElement('a');
        link.setAttribute('href', url);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setIsLoading(false);
        clearCSVFilters();
      } catch (err) {
        setIsLoading(false);
      }
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <Button onClick={handleClick}>
        {isLoading ? 'Carregando...' : `Download CSV`}
        {!isLoading && <FaFileDownload />}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <PopoverTitle>
          Você deseja exportar apenas os 100 primeiros usuários?
        </PopoverTitle>
        <ButtonsContainer>
          <PopoverConfirmButton onClick={() => downloadCSV(true)}>
            Sim
          </PopoverConfirmButton>
          <PopoverDenyButton onClick={() => downloadCSV(false)}>
            Não
          </PopoverDenyButton>
        </ButtonsContainer>
      </Popover>
    </>
  );
}
