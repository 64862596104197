const theme = {
  title: 'default',
  colors: {
    black: '#000000',
    darkBlue: '#023047',
    lightGray: '#E5E5E5',
    orange: '#FB8500',
    white: '#FFFFFF',
    grayText: '#CCCCCC',
    yellow: '#FBBC05',
    red: '#EA4335',
    green: '#95c730',
    shadowColor: '#121212',
    backgroundColorDarkMode: '#292d30',
    backgroundColorLightMode: '#f6f7f9',
    boxShadowDarkMode: '#00000025',
    boxShadowLightMode: '#00000025',
    lightBlue: '#0073ea',
    lightGray2: '#cccccc',
  },
  fonts: {
    mainFont: "'Roboto', sans-serif",
    textFont: "'Roboto', sans-serif",
  },
};

export { theme };
