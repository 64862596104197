/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { useSidebar } from '../../hooks/useSidebar';
import { useAuth } from '../../hooks/useAuth';
import { pictureStorage } from '../../services/firebase';

import { Menu, ToggleMenu, ArrowMenu, OptionMenu, UserInfo } from './styles';

import UserMenu from '../UserMenu';

// ícones tema escuro
import { ReactComponent as IconReconhecimento } from '../../assets/darkmode/Logo-Branco-Reconhecimento.svg';
import { ReactComponent as IconTable } from '../../assets/darkmode/Barras-Branca.svg';
import { ReactComponent as IconUsers } from '../../assets/darkmode/Users-Branco.svg';
import { ReactComponent as IconArrowDark } from '../../assets/darkmode/IconArrow.svg';

// Ícones tema claro
import { ReactComponent as IconReconhecimentoLight } from '../../assets/lightmode/Logo-Cinza-Reconhecimento.svg';
import { ReactComponent as IconTableLight } from '../../assets/lightmode/Barras-Cinza.svg';
import { ReactComponent as IconUsersLight } from '../../assets/lightmode/Users-Cinza.svg';
import { ReactComponent as IconArrowLight } from '../../assets/lightmode/IconArrow.svg';

// Ícones highlight
import { ReactComponent as IconReconhecimentoHighlight } from '../../assets/Logo-Azul-Reconhecimento.svg';
import { ReactComponent as IconTableHighlight } from '../../assets/Barras-Azul.svg';
import { ReactComponent as IconUsersHighlight } from '../../assets/Users-Azul.svg';

export const Sidebar = () => {
  // Função para pegar a rota
  const location = useLocation();

  // Variáveis
  const { theme, toggleMenu, setToggleMenu } = useSidebar();
  const path = location.pathname;
  const { isUserAdmin, userName, currentUser } = useAuth();

  // Estados
  const [toggleUserMenu, setToggleUserMenu] = useState(false);
  const [userPicture, setUserPicture] = useState('');

  // Funções
  const toggleMainMenu = () => {
    setToggleMenu(!toggleMenu);
  };

  // Variáveis de seleção de página
  const mainPageHighlight = path === '/home' ? 'optionHighlight' : '';
  const usersHighlight = path === '/users' ? 'optionHighlight' : '';
  const hashtagsHighlight = path === '/hashtags' ? 'optionHighlight' : '';

  // Eventos
  const openMenu = toggleMenu ? 'mainMenuOpen' : 'mainMenuClose';
  const toggleIcon = toggleMenu ? 'toggleMenuOpen' : 'toggleMenuClose';
  const arrowMenu = toggleMenu ? 'arrowMenuOpen' : 'arrowMenuClose';
  const optionMenu = toggleMenu ? 'optionOpen' : 'optionClose';
  const iconMenu = toggleMenu ? 'iconMenuOpen' : 'iconMenuClose';
  const userInfo = toggleMenu ? 'userInfoOpen' : 'userInfoClose';

  // Tema
  const mainMenuTheme = theme ? 'mainMenuDark' : 'mainMenuLight';
  const toggleMenuTheme = theme ? 'toggleMenuDark' : 'toggleMenuLight';
  const optionTheme = theme ? 'optionDark' : 'optionLight';
  const userInfoTheme = theme ? 'userInfoDark' : 'userInfoLight';

  // Variáveis de tema de ícones
  const iconReconhecimento = theme ? (
    <IconReconhecimento className={`iconMenu ${iconMenu}`} />
  ) : (
    <IconReconhecimentoLight className={`iconMenu ${iconMenu}`} />
  );
  const iconTable = theme ? (
    <IconTable className={`iconMenu ${iconMenu}`} />
  ) : (
    <IconTableLight className={`iconMenu ${iconMenu}`} />
  );
  const iconUsers = theme ? (
    <IconUsers className={`iconMenu ${iconMenu}`} />
  ) : (
    <IconUsersLight className={`iconMenu ${iconMenu}`} />
  );

  useEffect(() => {
    const loadUserPicture = async () => {
      await pictureStorage
        .ref(`${currentUser?.email}-userPicture.png`)
        .getDownloadURL()
        .then(url => {
          setUserPicture(url);
        });
    };
    loadUserPicture();
  }, []);

  return (
    <Menu className={`mainMenu ${openMenu} ${mainMenuTheme}`}>
      <ToggleMenu
        className={`toggleMenu ${toggleIcon} ${toggleMenuTheme}`}
        onClick={toggleMainMenu}
      >
        {theme ? (
          <ArrowMenu>
            <IconArrowDark className={`${arrowMenu}`} />
          </ArrowMenu>
        ) : (
          <ArrowMenu>
            <IconArrowLight className={`${arrowMenu}`} />
          </ArrowMenu>
        )}
      </ToggleMenu>
      <OptionMenu className="optionMenu">
        <Link to="/home" className="link">
          <div
            className={`option ${optionMenu} ${optionTheme} ${mainPageHighlight}`}
          >
            {path === '/home' ? (
              <IconReconhecimentoHighlight className={`iconMenu ${iconMenu}`} />
            ) : (
              iconReconhecimento
            )}
            {toggleMenu && <span>Página inicial</span>}
          </div>
        </Link>
        <Link to="/hashtags" className="link">
          <div
            className={`option ${optionMenu} ${optionTheme} ${hashtagsHighlight}`}
          >
            {path === '/hashtags' ? (
              <IconTableHighlight className={`iconMenu ${iconMenu}`} />
            ) : (
              iconTable
            )}
            {toggleMenu && <span>Painel de Tags</span>}
          </div>
        </Link>
        {isUserAdmin && (
          <Link to="/users" className="link">
            <div
              className={`option ${optionMenu} ${optionTheme}  ${usersHighlight}`}
            >
              {path === '/users' ? (
                <IconUsersHighlight className={`iconMenu ${iconMenu}`} />
              ) : (
                iconUsers
              )}
              {toggleMenu && <span>Usuários</span>}
            </div>
          </Link>
        )}
      </OptionMenu>
      <UserInfo
        className={`userInfo ${userInfo} ${userInfoTheme}`}
        onClick={() => setToggleUserMenu(!toggleUserMenu)}
      >
        <img src={userPicture} alt="Foto do usuário" className="userphoto" />
        {toggleMenu && <span>{userName}</span>}
      </UserInfo>
      {toggleUserMenu && <UserMenu />}
    </Menu>
  );
};
