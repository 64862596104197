/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { Redirect, Route } from 'react-router-dom';

import { useAuth } from '../hooks/useAuth';

type PrivateRouteProps = {
  component: React.ElementType;
  exact: boolean;
  path: string;
};

export function PrivateRoute({
  component: Component,
  exact,
  path,
}: PrivateRouteProps) {
  const { currentUser } = useAuth();
  return (
    <Route
      // eslint-disable-next-line react/jsx-props-no-spreading
      exact={exact}
      path={path}
      render={props => {
        // eslint-disable-next-line react/jsx-props-no-spreading
        return currentUser?.uid ? (
          <Component {...props} />
        ) : (
          <Redirect to="/" />
        );
      }}
    />
  );
}
