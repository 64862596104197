import styled from 'styled-components';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-family: ${props => props.theme.fonts.mainFont};
  gap: 1rem;
  margin-bottom: 2rem;

  input:last-of-type {
    margin: 0;
  }

  h2 {
    color: ${props => props.theme.colors.darkBlue};
    font-size: 2rem;
    margin-bottom: 1rem;
  }

  label {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    gap: 0.4rem;
    font-weight: bold;
    color: ${props => props.theme.colors.darkBlue};
    font-family: ${props => props.theme.fonts.textFont};
  }

  .isAdminModal {
    appearance: none;
    -webkit-appearance: none;
    height: 1.3rem;
    width: 1.3rem;
    background-color: #d5d5d5;
    border: 1px solid ${props => props.theme.colors.darkBlue};
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.2s;

    &:after {
      z-index: 3;
      content: '\\2022';
      color: white;
      font-size: 2rem;
      display: none;
    }

    &:hover {
      background-color: #a5a5a5;
    }

    &:checked {
      background-color: ${props => props.theme.colors.darkBlue};

      &:after {
        display: block;
      }
    }
  }

  button {
    width: 16rem;
    background: #0073ea;
    height: 3rem;
    font-size: 1.3rem;
    border: none;
    border-radius: 5px;
    /* margin-top: 1rem; */
    color: #fafbfc;
    font-weight: bold;

    &:hover {
      background-color: #00468e;
    }
  }
`;

export const ErrorMessage = styled.p`
  color: ${props => props.theme.colors.red};
  font-family: ${props => props.theme.fonts.mainFont};
  /* margin-bottom: 1rem; */
  font-weight: bold;
  font-size: 1.2rem;
`;

export const Success = styled.p`
  color: ${props => props.theme.colors.green};
  font-family: ${props => props.theme.fonts.mainFont};
  margin: 1rem;
  font-weight: bold;
  font-size: 1.2rem;
  align-text: center;
`;

export const DragAndDropContainer = styled.div`
  .title {
    font-family: ${props => props.theme.fonts.textFont};
    font-size: 1.3rem;
    margin-bottom: 0.5rem;
    &.dark {
      color: #ffffff;
    }
    &.light {
      color: #2c3135;
    }
  }
  .container {
    display: flex;
    height: 10rem;
    width: 20rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .drop-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0;
    width: 25rem;
    height: 10rem;
    border: 4px dashed #4aa1f3;
  }

  .icon {
    height: 80px;
    width: 80px;
    margin-left: 25px;
  }
  
  .fileName {
    display: flex;
    font-family: ${props => props.theme.fonts.textFont};
    font-size: 1rem;
    margin-top: 10px;
    &.dark {
      color: #ffffff;
    }
    &.light {
      color: #2c3135;
    }
  }

  input[type='file'] {
    display: none;
  }

  .inputFile {
    display: flex;
  align-items: center;
  width: 90%;
  overflow: auto;
  height: 2rem;
  border-radius: 8px;
  border: 1px solid #a8a8b3;
  cursor: pointer;
  font-family: ${props => props.theme.fonts.textFont};
  font-size: 1rem;
  overflow: hidden;
    &.dark {
      color: #ffffff;
    }
    &.light {
      color: #2c3135;
    }
  }

  }
`;
