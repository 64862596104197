import React from 'react';

import { useMembersTags } from '../../../hooks/useMembersTags';
import { useSidebar } from '../../../hooks/useSidebar';

import { List, ListItem, UserName, UserScore } from './styles';

export function UsersList() {
  const { mostInteractiveMembers } = useMembersTags();
  const { theme } = useSidebar();

  return (
    <List className={theme ? 'dark' : 'light'}>
      {mostInteractiveMembers.map(member => (
        <ListItem key={member.id}>
          <UserName>{member.name}</UserName>
          <UserScore>{member.score}</UserScore>
        </ListItem>
      ))}
    </List>
  );
}
