import styled from 'styled-components';

export const Container = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 92vh;
  &.dark {
    background-color: #2a2d30;
  }
  &.light {
    background-color: #fafbfc;
  }
`;

export const PageTitle = styled.h1`
  margin-top: 2rem;
  font-family: ${props => props.theme.fonts.mainFont};
  color: ${props => props.theme.colors.darkBlue};
  font-size: 3.375rem;
`;

export const CardsContainer = styled.div`
  width: 950px;
  margin-top: 3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Linha = styled.div`
  height: 60vh;
  &.dark {
    border-right: 1px solid #fafbfc;
  }
  &.light {
    border-right: 1px solid #2c3135;
  }
`;
