/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';

import Modal from 'react-modal';

import { CircularProgress } from '@material-ui/core';
import { useModals } from '../../hooks/useModals';
import { useUsers } from '../../hooks/useUsers';
import { pictureStorage } from '../../services/firebase';

import { Input } from '../Input';

import { Form, ErrorMessage, Success, DragAndDropContainer } from './styles';

import closeImg from '../../assets/close.svg';
import { ReactComponent as IconUploadLight } from '../../assets/lightmode/Upload-Cinza.svg';

Modal.setAppElement('#root');

export function EditModal() {
  const { isEditModalOpen, handleCloseEditModal, userId, userName, userEmail } =
    useModals();
  const { editUser } = useUsers();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [changeImage, setChangeImage] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [editSuccess, setEditSuccess] = useState('');
  const [errorImageType, setErrorImageType] = useState('');
  const [fileName, setFileName] = useState('');
  const [image, setImage] = useState<File>();

  const toggleCheckbox = () => {
    setIsChecked(!isChecked);
  };

  const toggleChangeImage = () => {
    setChangeImage(!changeImage);
  };

  const checkEmail = (emailValue: string) => {
    if (emailValue.trim()) {
      setEmail(emailValue);
    } else {
      throw Error();
    }
  };

  const handleSaveEdittedUser = async () => {
    setIsLoading(true);
    try {
      checkEmail(email);
      setEmailError('');
      await editUser(userId, name, email, isChecked);
      setIsLoading(false);
      setEditSuccess('Usuário editado com sucesso.');
      setTimeout(() => {
        setEditSuccess('');
        handleCloseEditModal();
      }, 3000);
    } catch (err) {
      setIsLoading(false);
      setEmailError('Email inválido');
    }
  };

  const handleSaveEdittedUserWithImage = async () => {
    setIsLoading(true);
    if (image) {
      try {
        checkEmail(email);
        setEmailError('');
        await editUser(userId, name, email, isChecked);
        pictureStorage
          .ref(`${email}-userPicture.png`)
          .put(image)
          .then(() => {
            setIsLoading(false);
            setErrorImageType('');
            setEditSuccess('Usuário editado com sucesso.');
            setTimeout(() => {
              setEditSuccess('');
              setIsChecked(false);
              setChangeImage(false);
              handleCloseEditModal();
            }, 3000);
          });
      } catch (err) {
        setIsLoading(false);
        setEmailError('Email inválido');
      }
    } else {
      setIsLoading(false);
      setErrorImageType('Selecione uma Imagem');
    }
  };

  useEffect(() => {
    setName(userName);
    setEmail(userEmail);
    setEmailError('');
  }, [isEditModalOpen]);

  const dragOver = (e: any) => {
    e.preventDefault();
  };

  const dragEnter = (e: any) => {
    e.preventDefault();
  };

  const dragLeave = (e: any) => {
    e.preventDefault();
  };

  const validateFile = (file: File) => {
    const validTypes = ['image/jpeg', 'image/jpg', 'image/png'];
    if (validTypes.indexOf(file.type) === -1) {
      return false;
    }
    return true;
  };

  const handleFiles = (file: File) => {
    if (validateFile(file)) {
      setFileName(file.name);
      setImage(file);
      setErrorImageType('');
    } else {
      setErrorImageType('Arquivo precisa ser .PNG, .JPEG ou .JPG');
    }
  };

  const fileDrop = (e: any) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      handleFiles(file);
    }
  };

  const fileSelect = (e: any) => {
    e.preventDefault();
    const file = e.target.files[0];
    if (file) {
      handleFiles(file);
    }
  };

  return (
    <Modal
      isOpen={isEditModalOpen}
      onRequestClose={handleCloseEditModal}
      overlayClassName="modal-overlay"
      className="modal-content"
    >
      <button
        type="button"
        onClick={handleCloseEditModal}
        className="close-modal-btn"
      >
        <img src={closeImg} alt="Fechar modal" />
      </button>
      <Form>
        <h2>Editar usuário</h2>
        <Input
          defaultValue={userName}
          onChange={event => {
            setName(event.target.value);
          }}
        />
        <Input
          defaultValue={userEmail}
          onChange={event => {
            setEmail(event.target.value);
          }}
        />
        <label htmlFor="isAdminModal">
          <input
            className="isAdminModal"
            type="checkbox"
            id="isAdminModal"
            name="isAdminModal"
            defaultChecked={isChecked}
            onClick={toggleCheckbox}
          />
          Usuário Administrador
        </label>
        <label htmlFor="changeImage">
          <input
            className="isAdminModal"
            type="checkbox"
            id="changeImage"
            name="changeImage"
            defaultChecked={changeImage}
            onClick={toggleChangeImage}
          />
          Editar foto do Usuário
        </label>
        {changeImage ? (
          <>
            <DragAndDropContainer>
              <p className="title light">Foto de Perfil</p>
              <div className="content">
                <div
                  className="drop-container"
                  onDragOver={dragOver}
                  onDragEnter={dragEnter}
                  onDragLeave={dragLeave}
                  onDrop={fileDrop}
                >
                  <IconUploadLight className="icon" />
                  <div className="container">
                    <p className="title light">Arraste e solte aqui a imagem</p>
                    <label className="inputFile light">
                      <input
                        type="file"
                        accept="image/*"
                        onChange={fileSelect}
                      />
                      Ou selecione um arquivo aqui
                    </label>
                    <p className="fileName light">
                      {fileName !== '' ? `Arquivo: ${fileName}` : ''}
                    </p>
                  </div>
                </div>
              </div>
            </DragAndDropContainer>
            {emailError && <ErrorMessage>{emailError}</ErrorMessage>}
            {errorImageType && <ErrorMessage>{errorImageType}</ErrorMessage>}
            {editSuccess && <Success>{editSuccess}</Success>}
            <button onClick={handleSaveEdittedUserWithImage} type="button">
              {isLoading ? (
                <p>
                  <CircularProgress color="inherit" />{' '}
                </p>
              ) : (
                'Salvar'
              )}
            </button>
          </>
        ) : (
          <>
            <div>
              {emailError && <ErrorMessage>{emailError}</ErrorMessage>}
              {errorImageType && <ErrorMessage>{errorImageType}</ErrorMessage>}
              {editSuccess && <Success>{editSuccess}</Success>}
            </div>
            <button onClick={handleSaveEdittedUser} type="button">
              {isLoading ? (
                <p>
                  <CircularProgress color="inherit" />{' '}
                </p>
              ) : (
                'Salvar'
              )}
            </button>
          </>
        )}
      </Form>
    </Modal>
  );
}
