import styled from 'styled-components';

export const Container = styled.header.attrs(props => ({
  className: props.className,
}))`
  &.Container {
    width: 100%;
    height: 5.85rem;
    position: relative;
    top: 0;
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 0 20px;
    z-index: 2;
  }
  &.ContainerMenuOpen {
    margin-left: 300px;
  }
  &.ContainerMenuClose {
    margin-left: 60px;
  }
  &.ContainerLightMode {
    background-color: ${props => props.theme.colors.backgroundColorLightMode};
    box-shadow: 0 3px 3px
      ${props => props.theme.colors.backgroundColorLightMode};
  }
  &.ContainerDarkMode {
    background-color: ${props => props.theme.colors.backgroundColorDarkMode};
    box-shadow: 0 3px 3px ${props => props.theme.colors.boxShadowDarkMode};
  }
`;
