import styled from 'styled-components';

export const StyledTable = styled.div`
  padding: 0 1rem;
  width: 80%;
  max-height: 390px;
  overflow-y: auto;
  margin: 0 auto;

  ::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 50%;
    background: ${props => props.theme.colors.lightGray};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${props => props.theme.colors.darkBlue};
    border-radius: 20px;
    border: 3px solid ${props => props.theme.colors.lightGray};
  }

  table {
    width: 100%;
    text-align: center;
    border-collapse: collapse;

    thead {
      text-align: center;
      font-family: ${props => props.theme.fonts.textFont};
      font-size: 1.5rem;
      font-weight: bold;
      &.dark {
        color: #ffffff;
        border-bottom: 1px solid #ffffff;
      }
      &.light {
        color: #2c3135;
        border-bottom: 1px solid #2c3135;
      }

      th {
        padding: 1rem 0;
        top: 0;
      }
    }

    tbody {
      tr {
        td {
          padding: 1rem 0 1rem 0;
          margin: 0;
          font-family: ${props => props.theme.fonts.textFont};
          &.dark {
            color: #ffffff;
            border-bottom: 1px solid #ffffff;
          }
          &.light {
            color: #2c3135;
            border-bottom: 1px solid #2c3135;
          }
        }
        &:hover {
          color: ${props => props.theme.colors.darkBlue};
        }
      }
    }
  }
`;

export const LoadContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1.5rem;
  width: 100%;
  max-height: 390px;
  margin: 0 auto;
`;
