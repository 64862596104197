import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { CircularProgress } from '@material-ui/core';

import { useAuth } from '../../hooks/useAuth';

import { Input } from '../../components/Input';

import {
  Description,
  Container,
  Main,
  Subtitle,
  Button,
  ForgottenPassword,
  Error,
  Success,
  Title,
} from './styles';

import logoReconhecimento from '../../assets/Logo-Azul-Reconhecimento.svg';
import LogoAlest from '../../assets/LogoAlest.svg';

export function Login() {
  const { login } = useAuth();
  const user = useAuth().currentUser;

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [loginSuccess, setLoginSuccess] = useState('');

  const clearErrors = () => {
    setPasswordError('');
    setEmailError('');
  };

  const history = useHistory();

  useEffect(() => {
    if (user != null) {
      history.push('/home');
    }
  }, []);

  const handleLogin = async (event: React.MouseEvent) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      clearErrors();
      await login(email, password);
      setLoginSuccess('Usuário logado!');
      setTimeout(() => {
        history.push('/home');
      }, 2000);
    } catch (error: any) {
      setIsLoading(false);
      switch (error.code) {
        case 'auth/invalid-email':
        case 'auth/user-disabled':
        case 'auth/user-not-found':
          setEmailError('Email inválido!');
          break;

        case 'auth/wrong-password':
          setPasswordError('Senha inválida!');
          break;

        default:
          break;
      }
    }
  };

  // eslint-disable-next-line func-names
  document.addEventListener('keypress', function (e) {
    if (e.key === 'Enter') {
      document.getElementById('btLogin')?.click();
    }
  });

  return (
    <Main>
      <Container className="ContainerLogoAlest">
        <img src={LogoAlest} alt="Logo da Alest" className="LogoAlest" />
      </Container>
      <Title>
        <Container className="ContainerLogoRec">
          <img src={logoReconhecimento} alt="Logo Reconhecimento" />
        </Container>
        Reconhecimento
      </Title>

      <Container className="Container">
        <Subtitle>Login</Subtitle>
        <Input
          type="email"
          placeholder="E-mail"
          value={email}
          onChange={event => setEmail(event.target.value)}
        />
        <Input
          type="password"
          placeholder="Senha"
          value={password}
          onChange={event => setPassword(event.target.value)}
        />
        {emailError && <Error>{emailError}</Error>}
        {passwordError && <Error>{passwordError}</Error>}
        {loginSuccess && <Success>{loginSuccess}</Success>}
        <Button id="btLogin" type="submit" onClick={handleLogin}>
          {isLoading ? (
            <p>
              <CircularProgress color="inherit" size={25} />{' '}
            </p>
          ) : (
            'Entrar'
          )}
        </Button>
        <Link to="/reset-password">
          <ForgottenPassword>Esqueci minha senha</ForgottenPassword>
        </Link>
        <Container className="ContainerLogoDescription">
          <Description>
            Potencialize a cultura do <br /> reconhecimento dentro da sua
            empresa!
          </Description>
        </Container>
      </Container>
    </Main>
  );
}
