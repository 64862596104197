/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/void-dom-elements-no-children */
/* eslint-disable prefer-destructuring */
import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';

import { pictureStorage } from '../../services/firebase';

import { Header } from '../../components/Header';
import { Input } from '../../components/Input';
import { EditModal } from '../../components/EditModal';
import { DeleteModal } from '../../components/DeleteModal';
import { UsersTable } from '../../components/UsersTable';

import { useUsers } from '../../hooks/useUsers';
import { useAuth } from '../../hooks/useAuth';
import { useSidebar } from '../../hooks/useSidebar';

import {
  Container,
  PageTitle,
  ButtonContainer,
  LoadMoreButton,
  ErrorMessage,
  Success,
  DragAndDropContainer,
} from './styles';

import { ReactComponent as IconUploadDark } from '../../assets/darkmode/Upload-Branco.svg';
import { ReactComponent as IconUploadLight } from '../../assets/lightmode/Upload-Cinza.svg';

export function Users() {
  const { isUserAdmin } = useAuth();
  const { theme } = useSidebar();

  if (!isUserAdmin) {
    return <Redirect to="/home" exact />;
  }

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [errorEmail, setErrorEmail] = useState('');
  const [errorPassword, setErrorPassword] = useState('');
  const [errorImageType, setErrorImageType] = useState('');
  const [isCreatingNewUser, setIsCreatingNewUser] = useState(false);
  const [creationSuccess, setCreationSuccess] = useState('');
  const [fileName, setFileName] = useState('');
  const [image, setImage] = useState<File>();

  const {
    createUser,
    isEmpty,
    isLoading,
    isLoadingNewUsers,
    allUsers,
    usersNames,
    getMoreUsers,
    getUsersNames,
  } = useUsers();

  useEffect(() => {
    getUsersNames();
  }, []);

  const isLoadedAllUsers = allUsers.length === usersNames.length;

  const toggleCheckbox = () => {
    setIsChecked(!isChecked);
  };

  const clearInputs = () => {
    setName('');
    setEmail('');
    setPassword('');
    setIsChecked(false);
  };

  const clearErrors = () => {
    setErrorPassword('');
    setErrorEmail('');
    setErrorImageType('');
  };

  const checkPasswordLength = (value: string, min: number, max: number) => {
    if (value.length < min) {
      setErrorPassword(`A senha precisa ter no mínimo ${min} caracteres.`);
      setIsCreatingNewUser(false);
    } else if (value.length > max) {
      setErrorPassword(`A senha precisa ter no máximo ${max} caracteres.`);
      setIsCreatingNewUser(false);
    } else {
      setPassword(password);
    }
  };

  const handleCreateUser = async () => {
    setIsCreatingNewUser(true);
    if (image) {
      try {
        clearErrors();
        checkPasswordLength(password, 6, 15);
        const formattedName = name.toLowerCase();
        await createUser(formattedName, email, password, isChecked);
        pictureStorage
          .ref(`${email}-userPicture.png`)
          .put(image)
          .then(() => {
            clearInputs();
            setIsCreatingNewUser(false);
            setCreationSuccess('Usuário cadastrado com sucesso.');

            setTimeout(() => {
              setCreationSuccess('');
            }, 4000);
          });
      } catch {
        setErrorEmail('Email já está em uso.');
        setIsCreatingNewUser(false);
      }
    } else {
      setErrorImageType('Nenhuma imagem selecionada!');
    }
  };

  const dragOver = (e: any) => {
    e.preventDefault();
  };

  const dragEnter = (e: any) => {
    e.preventDefault();
  };

  const dragLeave = (e: any) => {
    e.preventDefault();
  };

  const validateFile = (file: File) => {
    const validTypes = ['image/jpeg', 'image/jpg', 'image/png'];
    if (validTypes.indexOf(file.type) === -1) {
      return false;
    }
    return true;
  };

  const handleFiles = (file: File) => {
    if (validateFile(file)) {
      setFileName(file.name);
      setImage(file);
      setErrorImageType('');
    } else {
      setErrorImageType('Arquivo precisa ser .PNG, .JPEG ou .JPG');
    }
  };

  const fileDrop = (e: any) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      handleFiles(file);
    }
  };

  const fileSelect = (e: any) => {
    e.preventDefault();
    const file = e.target.files[0];
    if (file) {
      handleFiles(file);
    }
  };

  return (
    <>
      <Header />
      <Container className={theme ? 'dark' : 'light'}>
        <div className="wrapper">
          <div className={`form ${theme ? 'dark' : 'light'}`}>
            <h2 className={theme ? 'dark' : 'light'}>Novo Usuário</h2>
            <Input
              type="text"
              placeholder="Nome"
              value={name}
              onChange={event => setName(event.target.value)}
            />
            <Input
              type="email"
              placeholder="Email"
              value={email}
              onChange={event => setEmail(event.target.value)}
            />
            <Input
              type="password"
              placeholder="Senha"
              value={password}
              onChange={event => setPassword(event.target.value)}
            />

            <label htmlFor="isAdmin" className={theme ? 'dark' : 'light'}>
              <input
                type="checkbox"
                id="isAdmin"
                name="isAdmin"
                defaultChecked={isChecked}
                onClick={toggleCheckbox}
              />
              Usuário Administrador
            </label>
            <DragAndDropContainer>
              <p className={`title ${theme ? 'dark' : 'light'}`}>
                Foto de Perfil
              </p>
              <div className="content">
                <div
                  className="drop-container"
                  onDragOver={dragOver}
                  onDragEnter={dragEnter}
                  onDragLeave={dragLeave}
                  onDrop={fileDrop}
                >
                  {theme ? (
                    <IconUploadDark className="icon" />
                  ) : (
                    <IconUploadLight className="icon" />
                  )}
                  <div className="container">
                    <p className={`title ${theme ? 'dark' : 'light'}`}>
                      Arraste e solte aqui a imagem
                    </p>
                    <label className={`inputFile ${theme ? 'dark' : 'light'}`}>
                      <input
                        type="file"
                        accept="image/*"
                        onChange={fileSelect}
                      />
                      Ou selecione um arquivo aqui
                    </label>
                    <p className={`fileName ${theme ? 'dark' : 'light'}`}>
                      {fileName !== '' ? `Arquivo: ${fileName}` : ''}
                    </p>
                  </div>
                </div>
              </div>
            </DragAndDropContainer>
            {errorEmail && <ErrorMessage>{errorEmail}</ErrorMessage>}
            {errorPassword && <ErrorMessage>{errorPassword}</ErrorMessage>}
            {errorImageType && <ErrorMessage>{errorImageType}</ErrorMessage>}
            {creationSuccess && <Success>{creationSuccess}</Success>}
            <button onClick={handleCreateUser} type="button">
              {isCreatingNewUser ? (
                <p>
                  <CircularProgress color="inherit" size={25} />{' '}
                </p>
              ) : (
                'Criar'
              )}
            </button>
          </div>
          <div className="aside_table">
            <PageTitle className={theme ? 'dark' : 'light'}>
              Todos os Usuários
            </PageTitle>
            <UsersTable />
            <ButtonContainer>
              {!isEmpty &&
                !isLoading &&
                !isLoadingNewUsers &&
                !isLoadedAllUsers && (
                  <LoadMoreButton onClick={getMoreUsers} type="button">
                    Carregar Mais
                  </LoadMoreButton>
                )}
            </ButtonContainer>
          </div>
        </div>
      </Container>
      <EditModal />
      <DeleteModal />
    </>
  );
}
