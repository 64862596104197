import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 28.75rem;
  height: 25rem;
  border-radius: 5px;
  &.dark {
    background-color: #2a2d30;
  }
  &.light {
    background-color: #fafbfc;
  }
`;

export const CardTitle = styled.p`
  font-family: ${props => props.theme.fonts.textFont};
  font-weight: bold;
  margin-top: 1rem;
  font-size: 1.75rem;
  &.dark {
    color: #ffffff;
  }
  &.light {
    color: #2c3135;
  }
`;
