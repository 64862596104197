import styled from 'styled-components';

export const PageTitle = styled.h1`
  margin-top: 2rem;
  font-family: ${props => props.theme.fonts.mainFont};
  font-size: 1.2rem;
  &.dark {
    color: #ffffff;
  }
  &.light {
    color: #2c3135;
  }
`;

export const Container = styled.div`
  width: 100vw;
  height: 92vh;
  display: grid;
  grid-template-columns: 100%;
  &.dark {
    background-color: #2a2d30;
  }
  &.light {
    background-color: #fafbfc;
  }
  div.wrapper {
    margin-top: 3vh;
    display: grid;
    grid-template-columns: 40% 60%;

    div.form {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      font-family: ${props => props.theme.fonts.mainFont};
      gap: 1rem;
      margin-bottom: 2rem;
      &.dark {
        border-right: 1px solid #fafbfc;
      }
      &.light {
        border-right: 1px solid #2c3135;
      }

      input:last-of-type {
        margin: 0;
      }

      h2 {
        font-size: 2rem;
        margin-bottom: 2rem;
        &.dark {
          color: #ffffff;
        }
        &.light {
          color: #2c3135;
        }
      }

      label {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.2rem;
        gap: 0.4rem;
        font-weight: bold;
        font-family: ${props => props.theme.fonts.textFont};
        &.dark {
          color: #ffffff;
        }
        &.light {
          color: #2c3135;
        }
      }

      input[type='checkbox'] {
        appearance: none;
        -webkit-appearance: none;
        height: 1.1rem;
        width: 1.1rem;
        background-color: #d5d5d5;
        border: 1px solid #3f484f;
        border-radius: 50%;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: background-color 0.2s;

        &:after {
          z-index: 3;
          content: '\\2022';
          color: white;
          font-size: 2rem;
          display: none;
        }

        &:hover {
          background-color: #a5a5a5;
        }

        &:checked {
          background-color: #0073ea;

          &:after {
            display: block;
          }
        }
      }

      button {
        width: 16rem;
        background: #0073ea;
        height: 2rem;
        font-size: 1.3rem;
        border: none;
        border-radius: 5px;
        margin-top: 0.5rem;
        color: #fafbfc;
        font-weight: bold;

        &:hover {
          background-color: #00468e;
        }
      }
    }

    .aside_table {
      h1 {
        font-size: 2.5rem;
        margin: 0 0 2rem 0;
        text-align: center;
      }

      .line {
        margin: 0 auto;
        width: 75%;
        height: 1rem;
        border-bottom: 1px solid ${props => props.theme.colors.darkBlue};
      }
    }
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  margin-top: 1rem;
`;

export const DragAndDropContainer = styled.div`
  .title {
    font-family: ${props => props.theme.fonts.textFont};
    font-size: 1.3rem;
    margin-bottom: 0.5rem;
    &.dark {
      color: #ffffff;
    }
    &.light {
      color: #2c3135;
    }
  }
  .container {
    display: flex;
    height: 10rem;
    width: 20rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .drop-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0;
    width: 25rem;
    height: 10rem;
    border: 4px dashed #4aa1f3;
  }

  .icon {
    height: 80px;
    width: 80px;
    margin-left: 25px;
  }
  
  .fileName {
    display: flex;
    font-family: ${props => props.theme.fonts.textFont};
    font-size: 1rem;
    margin-top: 10px;
    &.dark {
      color: #ffffff;
    }
    &.light {
      color: #2c3135;
    }
  }

  input[type='file'] {
    display: none;
  }

  .inputFile {
    display: flex;
  align-items: center;
  width: 90%;
  overflow: auto;
  height: 2rem;
  border-radius: 8px;
  border: 1px solid #a8a8b3;
  cursor: pointer;
  font-family: ${props => props.theme.fonts.textFont};
  font-size: 0.5rem;
  overflow: hidden;
    &.dark {
      color: #ffffff;
    }
    &.light {
      color: #2c3135;
    }
  }

  }
`;

export const LoadMoreButton = styled.button`
  height: 2rem;
  width: 10rem;
  margin: 0 auto;
  border-radius: 5px;
  border: none;
  background-color: #0073ea;
  color: #fafbfc;
  font-weight: bold;
  font-size: 1rem;
  transition: filter 0.2s;
  :hover {
    background-color: #00468e;
  }
`;

export const ErrorMessage = styled.p`
  color: ${props => props.theme.colors.red};
  font-family: ${props => props.theme.fonts.textFont};
  font-weight: bold;
  font-size: 1.2rem;
`;

export const Success = styled.p`
  color: ${props => props.theme.colors.green};
  font-family: ${props => props.theme.fonts.mainFont};
  margin: 1rem;
  font-weight: bold;
  font-size: 1.2rem;
  align-text: center;
`;
