import React, { ButtonHTMLAttributes, ReactNode } from 'react';

import { StyledButton } from './styles';

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  mode: 'edit' | 'delete';
  children: ReactNode;
};

export function Button({ children, mode, ...props }: ButtonProps) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <StyledButton {...props} mode={mode}>
      {children}
    </StyledButton>
  );
}
