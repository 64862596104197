import styled from 'styled-components';

// ${props => props.theme.colors.mainColor};

export const Container = styled.div.attrs(props => ({
  className: props.className,
}))`
  &.Container {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 600px;
  }
  &.ContainerLogoRec {
    margin-right: 10px;
  }
  &.ContainerLogoAlest {
    height: 60px;
    width: 100vw;
  &.ContainerLogoAlest img{
    width: 80px;
    margin: 10px;
  }
  &.ContainerLogoDescription{
    display: flex;
    justify-content: center;
    text-align: center;
  }
`;

export const Main = styled.main`
  font-family: 'Roboto', sans-serif;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${props => props.theme.colors.white};

  a {
    text-decoration: none;
  }

  input {
    width: 400px;
    height: 30px;
    border-radius: 10px;
    border: 1px solid #cccccc;
    margin: 10px 0;
    text-indent: 10px;
    background-color: #f0f2f5;
    padding: 17px 0px 17px 6px;
  }

  input:focus {
    border: 1px solid #0073ea;
    text-decoration: none;
    outline: 1px solid #0073ea;
  }
`;

export const Title = styled.h1`
  height: 100px;
  width: 65%;
  margin: 20px 17.5%;
  border-bottom: 1px solid #cccccc;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #0073ea;
`;

export const Subtitle = styled.h2`
  font-family: ${props => props.theme.fonts.textFont};
  font-size: 24px;
  font-weight: bold;
  color: #3f484f;
  margin-bottom: 10px;
`;

export const ForgottenPassword = styled.h2`
  color: #0073ea;
  font-family: ${props => props.theme.fonts.textFont};
  font-size: 1rem;
  cursor: pointer;
  margin-bottom: 70px;
  &:hover {
    text-decoration: underline;
  }
`;

export const Button = styled.button`
  width: 405px;
  height: 35px;
  border-radius: 10px;
  border: none;
  background-color: #0073ea;
  color: ${props => props.theme.colors.white};
  margin: 10px 0;
  font-size: 15px;
  cursor: pointer;
  transition: all ease 0.2s;

  &:hover {
    background-color: #0061c5;
    border: none;
    outline: none;
  }
`;

export const Error = styled.p`
  color: ${props => props.theme.colors.red};
  font-family: ${props => props.theme.fonts.mainFont};
  margin-bottom: 1rem;
  font-weight: bold;
  font-size: 1.2rem;
`;

export const Success = styled.p`
  color: ${props => props.theme.colors.green};
  font-family: ${props => props.theme.fonts.mainFont};
  margin-bottom: 1rem;
  font-weight: bold;
  font-size: 1.2rem;
`;

export const Description = styled.p`
  text-align: center;
  font-style: italic;
  color: #7d838c;
  font-size: 30px;
`;
