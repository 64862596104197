import React, { useState } from 'react';

import Select, { OptionTypeBase } from 'react-select';
import makeAnimated from 'react-select/animated';

import { useMembersTags } from '../../../hooks/useMembersTags';
import { useSidebar } from '../../../hooks/useSidebar';

import { Container } from './styles';

const animatedComponents = makeAnimated();

type ReceivedValue = {
  value: string;
  label: string;
};

export function SelectDepartament() {
  const { departments, getMembersByDepartmentFilter } = useMembersTags();
  const { theme } = useSidebar();

  const [placeholder, setPlaceholder] = useState('Filtrar por departamento');

  const options = departments.map(owner => {
    return {
      value: owner,
      label: owner,
    };
  });

  const handleChange = (
    receivedValue: ReceivedValue[] | OptionTypeBase | null,
  ) => {
    const selectedDepartments = receivedValue?.map((option: ReceivedValue) => {
      return option.label;
    });

    getMembersByDepartmentFilter(selectedDepartments);
  };

  const handleFocus = () => {
    setPlaceholder('');
  };

  const handleBlur = () => {
    setPlaceholder('Filtrar por departamento');
  };

  return (
    <Container
      onFocus={handleFocus}
      onBlur={handleBlur}
      className={theme ? 'dark' : 'light'}
    >
      {theme ? (
        <Select
          isMulti
          isClearable
          onChange={handleChange}
          options={options}
          components={animatedComponents}
          placeholder={placeholder}
          classNamePrefix="react-select-dark"
        />
      ) : (
        <Select
          isMulti
          isClearable
          onChange={handleChange}
          options={options}
          components={animatedComponents}
          placeholder={placeholder}
          classNamePrefix="react-select-light"
        />
      )}
    </Container>
  );
}
