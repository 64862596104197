import styled from 'styled-components';

type ButtonProps = {
  mode: 'edit' | 'delete';
};

export const StyledButton = styled.button<ButtonProps>`
  width: 3.5rem;
  height: 2rem;
  color: #fafbfc;
  font-weight: bold;
  font-family: ${props => props.theme.fonts.textFont};
  margin-right: ${props => (props.mode === 'edit' ? '5px' : '0')};
  background-color: #0073ea;
  border: none;
  border-radius: 5px;
  font-size: 0.9rem;
  transition: filter 0.2s;
  &:hover {
    background-color: #00468e;
  }
`;
