import styled from 'styled-components';

export const StyledTable = styled.div`
  table {
    tbody {
      tr {
        td:first-of-type {
          font-weight: bold;
        }
        td:last-of-type {
          padding-left: 1.5rem;
        }
      }
    }
  }
  padding: 0 1.5rem;
  width: 90%;
  max-height: 350px;
  overflow-y: auto;
  margin: 0 auto;

  ::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 50%;
    background: #cccccc;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #cccccc;
    border-radius: 20px;
    border: 3px solid #cccccc;
  }

  table {
    width: 100%;
    text-align: center;
    border-collapse: collapse;

    thead {
      text-align: center;
      font-family: ${props => props.theme.fonts.textFont};
      font-size: 1.5rem;
      font-weight: bold;
      &.dark {
        color: #ffffff;
        border-bottom: 1px solid #ffffff;
      }
      &.light {
        color: #2c3135;
        border-bottom: 1px solid #2c3135;
      }
      tr {
        th:last-of-type {
          padding-left: 1.5rem;
        }

        th:first-of-type {
          text-align: start;
          padding: 1rem 0;
        }
      }

      th {
        padding: 1rem;
        position: sticky;
        top: 0;
        &.dark {
          background-color: #2a2d30;
        }
        &.light {
          background-color: #fafbfc;
        }
      }
    }

    tbody {
      .dark {
        background-color: #2a2d30;
      }
      .light {
        background-color: #fafbfc;
      }
      tr {
        td {
          padding: 1rem;
          margin: 0;
          font-family: ${props => props.theme.fonts.textFont};
          text-align: center;
          &.dark {
            color: #fafbfc;
            border-bottom: 1px solid #fafbfc;
            :hover {
              color: #aed8ff !important;
            }
          }
          &.light {
            color: #2c3135;
            border-bottom: 1px solid #cccccc;
            :hover {
              color: #aed8ff !important;
            }
          }
          h3 {
            &.dark {
              color: #ffffff;
            }
            &.light {
              color: #2c3135;
            }
          }
        }

        td:first-of-type {
          font-weight: bold;
          text-align: start;
          padding-left: 0;
        }
        td:last-of-type {
          padding-left: 1.5rem;
        }
      }
    }
  }
`;
