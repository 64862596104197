import axios from 'axios';

export const csvAPI = axios.create({
  baseURL: process.env.REACT_APP_CSV_API_URL,
  headers: {
    post: {
      'Content-Type': 'application/json',
      'access-control-allow-origin': '*',
    },
  },
});
