import React, { useEffect } from 'react';

import { useAuth } from '../../hooks/useAuth';
import { useMembersTags } from '../../hooks/useMembersTags';
import { useSidebar } from '../../hooks/useSidebar';

import { Header } from '../../components/Header';
import { DashboardCard } from '../../components/DashboardCard';

import { UsersList } from './UsersList';
import { TagsChart } from './TagsChart';

import { Container, CardsContainer, Linha } from './styles';

export function Home() {
  const { getCurrentUser } = useAuth();
  const { getTagsInteractions, getMostInteractiveMembers } = useMembersTags();
  const { theme } = useSidebar();

  useEffect(() => {
    getCurrentUser();
    getTagsInteractions();
    getMostInteractiveMembers();
  }, []);

  return (
    <>
      <Header />
      <Container className={theme ? 'dark' : 'light'}>
        <CardsContainer>
          <DashboardCard title="Tags mais usadas">
            <TagsChart />
          </DashboardCard>
          <Linha className={theme ? 'dark' : 'light'} />
          <DashboardCard title="Maior número de interações">
            <UsersList />
          </DashboardCard>
        </CardsContainer>
      </Container>
    </>
  );
}
