/* eslint-disable no-unused-vars */
import React, { useState, createContext, ReactNode } from 'react';

type SidebarProviderProps = {
  children: ReactNode;
};

type SidebarContextType = {
  theme: Boolean;
  toggleMenu: Boolean;
  setTheme: Function;
  setToggleMenu: Function;
};

export const SidebarContext = createContext<SidebarContextType>(
  {} as SidebarContextType,
);

export function SidebarProvider({ children }: SidebarProviderProps) {
  const [theme, setTheme] = useState(
    JSON.parse(localStorage.getItem('theme') || 'false'),
  );
  const [toggleMenu, setToggleMenu] = useState(false);

  return (
    <SidebarContext.Provider
      value={{
        theme,
        toggleMenu,
        setTheme,
        setToggleMenu,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
}
