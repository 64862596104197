import React, { useState } from 'react';
import { useSidebar } from '../../hooks/useSidebar';
import { useAuth } from '../../hooks/useAuth';

import {
  UserMenuStyle,
  UserOptionTheme,
  IconUser,
  ToggleTheme,
  Success,
} from './styles';

import Toggle from '../Toggle';

import { ReactComponent as IconLuzDark } from '../../assets/darkmode/IconLuz.svg';
import { ReactComponent as IconSairDark } from '../../assets/darkmode/IconSair.svg';

import { ReactComponent as IconLuzLight } from '../../assets/lightmode/IconLuz.svg';
import { ReactComponent as IconSairLight } from '../../assets/lightmode/IconSair.svg';

const UserMenu = () => {
  const { theme, toggleMenu } = useSidebar();
  const { logout } = useAuth();
  const [logoutSuccess, setLogoutSuccess] = useState('');

  const toggleUserMenu = toggleMenu ? 'userMenuOpen' : 'userMenuClose';
  const userMenuTheme = theme ? 'userMenuDark' : 'userMenuLight';
  const userOptionTheme = theme ? 'userOptionDark' : 'userOptionLight';

  function handleLogout() {
    setLogoutSuccess('Usuário desconectado');
    setTimeout(() => {
      logout();
    }, 3000);
  }

  return (
    <UserMenuStyle className={`userMenu ${userMenuTheme} ${toggleUserMenu}`}>
      <UserOptionTheme className={`${userOptionTheme}`}>
        {theme ? (
          <IconUser>
            <IconLuzDark className="iconUser" />
          </IconUser>
        ) : (
          <IconUser>
            <IconLuzLight className="iconUser" />
          </IconUser>
        )}
        <span>Modo escuro</span>
        <ToggleTheme className="toggleTheme">
          <Toggle />
        </ToggleTheme>
      </UserOptionTheme>
      <UserOptionTheme
        className={`${userOptionTheme}`}
        onClick={() => handleLogout()}
      >
        {theme ? (
          <IconUser>
            <IconSairDark className="iconUser" />
          </IconUser>
        ) : (
          <IconUser>
            <IconSairLight className="iconUser" />
          </IconUser>
        )}
        <span>Sair</span>
        {logoutSuccess && <Success>{logoutSuccess}</Success>}
      </UserOptionTheme>
    </UserMenuStyle>
  );
};

export default UserMenu;
