import React, { useState, createContext, ReactNode } from 'react';

type ModalProviderProps = {
  children: ReactNode;
};

type ModalContextType = {
  isEditModalOpen: boolean;
  isDeleteModalOpen: boolean;
  // eslint-disable-next-line no-unused-vars
  handleOpenEditModal: (id: string, name: string, email: string) => void;
  // eslint-disable-next-line no-unused-vars
  handleOpenDeleteModal: (id: string, name: string, email: string) => void;
  handleCloseEditModal: () => void;
  handleCloseDeleteModal: () => void;
  userId: string;
  userName: string;
  userEmail: string;
};

export const ModalContext = createContext<ModalContextType>(
  {} as ModalContextType,
);

export function ModalProvider({ children }: ModalProviderProps) {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const [userId, setUserId] = useState('aaa');
  const [userName, setUserName] = useState('');
  const [userEmail, setUserEmail] = useState('');

  function handleOpenEditModal(id: string, name: string, email: string) {
    setIsEditModalOpen(true);
    setUserId(id);
    setUserName(name);
    setUserEmail(email);
  }

  function handleOpenDeleteModal(id: string, name: string, email: string) {
    setIsDeleteModalOpen(true);
    setUserId(id);
    setUserName(name);
    setUserEmail(email);
  }

  function handleCloseEditModal() {
    setIsEditModalOpen(false);
  }

  function handleCloseDeleteModal() {
    setIsDeleteModalOpen(false);
  }

  return (
    <ModalContext.Provider
      value={{
        isEditModalOpen,
        isDeleteModalOpen,
        handleOpenEditModal,
        handleOpenDeleteModal,
        handleCloseEditModal,
        handleCloseDeleteModal,
        userId,
        userName,
        userEmail,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
}
