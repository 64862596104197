/* eslint-disable no-undef */
import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: none;
  }
  
  html{
    @media (max-width: 1080px) {
      font-size: 93.75%; // 15px
    }
    @media (max-width: 720px) {
      font-size: 87.5%; //14px
    }
  }
  body{
    -webkit-font-font-smoothing: antialiased;

    ::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #cccccc;
    }

    overflow-x: hidden;

  }
  body, input, textarea, button{
    font-weight: 400;
    color: black;
  }
  h1, h2, h3, h4, h5, h6, strong{
    color: black;
    font-weight: 600
  }
  button{
    cursor: pointer;
  }
  [disabled] {
    opacity: 0.6;
    cursor: not-allowed;
  }

  .modal-overlay{
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .modal-content{
    width: 100%;
    max-width: 576px;
    background-color: #E5E5E5;
    padding: 3rem;
    position: relative;
    border-radius: 0.25rem;
  }
  .close-modal-btn{
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
    border: 0;
    background-color: transparent;
    transition: filter 0.2s;
    &:hover{
      filter: brightness(0.8)
    }
  }
`;
