import React, { ReactNode } from 'react';
import { useSidebar } from '../../hooks/useSidebar';

import { Container, CardTitle } from './styles';

type DashboardCardProps = {
  title: string;
  children: ReactNode;
};

export function DashboardCard({ title, children }: DashboardCardProps) {
  const { theme } = useSidebar();
  return (
    <Container className={theme ? 'dark' : 'light'}>
      <CardTitle className={theme ? 'dark' : 'light'}>{title}</CardTitle>
      {children}
    </Container>
  );
}
