import styled from 'styled-components';

export const Container = styled.div`
  div {
    padding: 4.5px;
    font-family: ${props => props.theme.fonts.textFont};
    text-align: left;
  }
  &.dark {
    background-color: #2a2d30;
  }
  &.light {
    background-color: #fafbfc;
  }

  .react-select-dark__control {
    padding-left: 10px;
    border: 1px solid #3f484f;
    background-color: #45525b;
  }

  .react-select-dark__indicator-separator {
    visibility: hidden;
  }

  .react-select-dark__indicator {
    color: #fafbfc;
    cursor: pointer;
  }

  .react-select-dark__placeholder {
    color: #fafbfc;
  }

  .react-select-dark__control {
    color: #fafbfc;
  }

  .react-select-dark__value-container {
    max-height: 2rem;
    overflow-y: auto;
  }

  .react-select-dark__menu {
    color: #fafbfc;
    background-color: #45525b;
  }

  .react-select-dark__input {
    color: #fafbfc;
  }

  .react-select-dark__option {
    :first-of-type {
      color: #fafbfc;
      background-color: #45525b;
    }
    :hover {
      background-color: #a7bffa;
      color: #0073ea;
    }
  }

  .react-select-light__control {
    padding-left: 10px;
    border: 1px solid #cccccc;
    background-color: #f6f7f8;
  }

  .react-select-light__indicator-separator {
    visibility: hidden;
  }

  .react-select-light__indicator {
    color: #2c3135;
    cursor: pointer;
  }

  .react-select-light__placeholder {
    color: #2c3135;
  }

  .react-select-light__control {
    color: #2c3135;
  }

  .react-select-light__value-container {
    max-height: 2rem;
    overflow-y: auto;
  }

  .react-select-light__menu {
    color: #2c3135;
    background-color: #f6f7f8;
  }

  .react-select-light__input {
    color: #2c3135;
  }

  .react-select-light__option {
    :first-of-type {
      color: #2c3135;
      background-color: #f6f7f8;
    }
    :hover {
      background-color: #a7bffa;
      color: #0073ea;
    }
  }
`;
