/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
import React, { InputHTMLAttributes } from 'react';

import { StyledInput } from './styles';

type InputProps = InputHTMLAttributes<HTMLInputElement> & {
  placeholder?: string;
  value?: string;
};

export function Input({ placeholder, ...props }: InputProps) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <StyledInput {...props} placeholder={placeholder} />;
}
